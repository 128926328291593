import "../ClientConfigModal/ClientConfigModal.css";
import "../Modal.css";
import toast from 'react-hot-toast';
import { Transition } from "react-transition-group";

import { useEffect, useState } from 'react';

function ClientCreationModal({ isOpen, setOpen, selectedClient, updateClientList }) {
    const [name, setName] = useState("");
    const [siteAdmin, setSiteAdmin] = useState("");
    const [siteAdminPassword, setSiteAdminPassword] = useState("");
    const [clientId, setClientId] = useState();
    const [country, setCountry] = useState();
    const [selectedActive, setSelectedActive] = useState(selectedClient ? selectedClient.IsActive : false);
    const [selectedProduction, setSelectedProduction] = useState(selectedClient ? selectedClient.IsProduction : false);
    const [selectedAllows, setSelectedAllows] = useState(selectedClient ? selectedClient.AllowsImage : false);
    const [selectedState, setSelectedState] = useState("");
    const [description, setDescription] = useState("");
    const [error, setError] = useState(false);
    const [invalidClient, setErrorClient] = useState(false);
    const [invalidCountry, setErrorCountry] = useState(false);
    const [invalidState, setErrorState] = useState(false);
    const [selectedPicture, setSelectedPicture] = useState("");
        const [selectedPay, setSelectedPay] = useState(selectedClient ? selectedClient.AllowPaymentLink : false);
    const [PayLink, setPayLink] = useState(selectedClient ? selectedClient.PaymentLink : "");
    const [stateList, setStateList] = useState([]);

    const closeModal = () => { setOpen(false) };

    // form validation
    const handleValidation = () => {
        setError(false);
        let isFormOk = true;
        setErrorClient(false);

        if (!name) {
            setErrorClient(true);
            isFormOk = false;
        }

        setErrorCountry(false);
        if (!country) {
            setErrorCountry(true);
            isFormOk = false;
        }

        setErrorState(false);
        if (!selectedState) {
            setErrorState(true);
            isFormOk = false;
        }

        return isFormOk;


    }

    //Image 
    const toggleTxtPicturelink = (event) => {

        const pictureTextBox = document.getElementById('pictureLevelSelTextBox');
        if (event.target.value === 'allowImgStatus') {
            pictureTextBox.disabled = false;

        } else {
            setSelectedPicture("");
            pictureTextBox.disabled = true;
        }

    }


   //Payment Link Handlers
    const handleChangePaymentLinkRadio = (event) => {
        if (event.target.value === 'AllowPaymentlink') {
            setSelectedPay(true);

        } else {
            setSelectedPay(false);
        }
    };

    const toggleTxtPaylink = (event) => {
        const payTextBox = document.getElementById('payLinkTextbox');
        if (event.target.value === 'AllowPaymentlink') {
            payTextBox.disabled = false;


        } else {
            setPayLink("");
            payTextBox.disabled = true;
        }

    };



    //TODO: ?? Not sure why this is empty?
    const addClient = () => {
        let fetchBody = {
            City: name,
            StateProvince: selectedState,
            Country: country,
            Description: description,
            AllowsImage: selectedAllows,
            IsActive: selectedActive,
            IsProduction: selectedProduction,
            PictureLevel: selectedPicture,
            PaymentLink: PayLink,
            AllowPaymentLink: selectedPay
        };

        if (handleValidation()) {

            setError(false);
            let accessToken = localStorage.getItem('accessToken');
            fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/client/`, {
                method: "POST",
                mode: 'cors',
                credentials: "include",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': accessToken
                },
                body: JSON.stringify(fetchBody)
            }, [accessToken])

                .then(res => res.json(), [accessToken])

                .then(value => {
                    if (value.error) {
                        toast.error("An error occured");
                        //setError(value.error)
                    }
                    else {
                        toast.success("Client Added");
                        closeModal();
                        let loc = window.location;
                        window.location.replace(loc);
                        updateClientList((prev) => {
                            let temp = prev;
                            temp.push(value);
                            return temp;
                        })

                    }
                }, [accessToken]);

        }
        else {


        }
    }

    /*  const updateClientsState = (prev, data) => {
         let temp = prev;
         for (let i = 0; i < temp.length; i++) {
             if (temp[i].ClientID == data.ClientID) {
                 temp[i] = data;
                 break;
             }
         }
         return temp;
     } */

    useEffect(() => {
        setName(selectedClient ? selectedClient.City : "");
        setSelectedState(selectedClient ? selectedClient.StateProvince : "");
        setCountry(selectedClient ? selectedClient.Country : "");
        setDescription(selectedClient ? selectedClient.Description : "");
        setSelectedActive(selectedClient ? selectedClient.IsActive : true);
        setSelectedAllows(selectedClient ? selectedClient.AllowsImage : true);
        setSelectedProduction(selectedClient ? selectedClient.IsProduction : false);
        setSelectedPay(selectedClient ? selectedClient.AllowPaymentLink : true);
        setPayLink(selectedClient ? selectedClient.PaymentLink : "");

    }, [selectedClient])

    /**
     * When selectedCountry is set, open state selection and fetch state list
     */
    useEffect(() => {
        //Get list of states from backend
        fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/states`).then(res => res.json()).then(value => setStateList(value));
    }, []);

    // if(isOpen)
    return (
        <Transition in={isOpen} timeout={{ enter: 0, exit: 150 }} appear>
            {state => (
                <div className={`client-creation-modal modal-container ${state}`}>
                    <div className="screen" onClick={closeModal}></div>
                    <div className="modal">
                        <button className="close-modal-btn" onClick={closeModal}>✕</button>
                        <div className="modal-header">
                            <h1>Please enter the client Information:</h1>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div className="input-group">
                                    <label htmlFor="name-input">Client Name</label>
                                    <input type="name" id="name-input" autoComplete="off" value={name} onChange={(event) => setName(event.target.value)} />

                                    {invalidClient ? <p className='error-msg left'>Client Name is required.</p> : <></>}
                                </div>
                                <div className='input-group'>
                                    <label htmlFor='country-select'>Country</label>
                                    <input type="name" id='country-select' autoComplete='off' value={country} onChange={(event) => setCountry(event.target.value)} />
                                    {invalidCountry ? <p className='error-msg left'>Country is required.</p> : <></>}
                                </div>
                                <div className="input-group">
                                    <label htmlFor='state-selectClientCreation'>State/Province</label>
                                    <input id="state-selectClientCreation" list="state-list" autoCorrect="off" value={selectedState} onChange={(event) => setSelectedState(event.target.value)} />
                                    {invalidState ? <p className='error-msg left'>State/Province is required.</p> : <></>}
                                    <datalist id="state-list">
                                        <option value="" />
                                        {
                                            // stateList.map((input, index) => {
                                            //     return(<option key={`state${index}`} value={input}/>);
                                            // })
                                        }
                                    </datalist>
                                </div>
                                <div className="input-group">
                                    <label htmlFor="description-input">Description</label>
                                    <input type="name" id='description-input' autoComplete='off' value={description} onChange={(event) => setDescription(event.target.value)} />
                                </div>
                                <div className='status-section modal-section'>
                                    <div className='input-group'>
                                        <div className='status-label'>
                                            <label htmlFor='status-selection'>Status</label>
                                        </div>
                                        <div className='radio-selection'>
                                            <label htmlFor='active-status'>Active</label>
                                            <input type='radio' id='active-status' name='select-status' value='true' checked={selectedActive} onChange={(event) => setSelectedActive(true)} />
                                        </div>
                                        <div className='radio-selection'>
                                            <label htmlFor='inactive-status'>Inactive</label>
                                            <input type='radio' id='inactive-status' name='select-status' value='false' checked={!selectedActive} onChange={(event) => setSelectedActive(false)} />
                                        </div>
                                    </div>
                                </div>
                                <div className='status-picture-section modal-section'>
                                    <div className='input-group'>
                                        <div className='production-label'>
                                            <label htmlFor='production-selection'>Type</label>
                                        </div>
                                        <div className='radio-selection'>
                                            <label htmlFor='production-status'>Production</label>
                                            <input type='radio' id='production-status' name='select-type' value='true' checked={selectedProduction} onChange={(event) => setSelectedProduction(true)} />
                                        </div>
                                        <div className='radio-selection'>
                                            <label htmlFor='test-status'>Test</label>
                                            <input type='radio' id='test-status' name='select-type' value='false' checked={!selectedProduction} onChange={(event) => setSelectedProduction(false)} />
                                        </div>
                                    </div>
                                </div>
                                {/* Image Link section */}
                                <div className="status-picture-section modal-section">
                                    <div className='input-group'>
                                        <div className='status-picture-label'>
                                            <label htmlFor='status-picture-selection'>Allows Image</label>
                                        </div>
                                        <div className='radio-selection'>
                                            <label htmlFor='allow-status'>Allows</label>
                                            <input type='radio' id='allowImgStatus'
                                                name='select-imagestatus'
                                                value='allowImgStatus'
                                                checked={selectedAllows}
                                                onChange={(event) => {
                                                    toggleTxtPicturelink(event);
                                                    setSelectedAllows(true);
                                                }} />
                                        </div>
                                        <div className='radio-selection'>
                                            <label htmlFor='disallow-status'>Deny</label>
                                            <input type='radio' id='disallow-status'
                                                name='select-imagestatus'
                                                value='false'
                                                checked={!selectedAllows}
                                                onChange={(event) => {
                                                    setSelectedAllows(false);
                                                    toggleTxtPicturelink(event);

                                                }} />
                                        </div>
                                    </div>
                                    <div className='input-group'>
                                        <label htmlFor='picture-selection'>Picture Level (Per Property)</label>
                                        <input id='pictureLevelSelTextBox' list='source-list' autoComplete='off' value={selectedPicture} onChange={(event) => setSelectedPicture(event.target.value)} />
                                    </div>
                                </div>

                               {/* Payment Link section */}
                                <div className="status-pay-selection modal-section">
                                    <div className="input-group">
                                        <div className="status-pay-label">
                                            <label htmlFor="status-pay-selection">Allows Payment link</label>
                                        </div>
                                        <div className="radio-selection">
                                            <label htmlFor="AllowPaymentLinkcreate">Allows</label>
                                            <input
                                                type="radio"
                                                id="AllowPaymentLinkCreate"
                                                name="AllowPayment"
                                                value="AllowPaymentlink"
                                                checked={selectedPay}
                                                onChange={(event) => {
                                                    handleChangePaymentLinkRadio(event);
                                                    toggleTxtPaylink(event);
                                                }}
                                            />
                                        </div>
                                        <div className="radio-selection">
                                            <label htmlFor="status-pay-selection">Deny</label>
                                            <input
                                                type="radio"
                                                id="DenyPaymentLinkOption"
                                                name="DenyPaymentLinkOption"
                                                value="DenyPaymentLinkOption"
                                                checked={!selectedPay}
                                                onChange={(event) => {
                                                    handleChangePaymentLinkRadio(event);
                                                    toggleTxtPaylink(event);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="input-group">
                                        <label htmlFor="link-selection">Payment Link</label>
                                        <input type="text" id="payLinkTextbox" autoComplete="off" value={PayLink}
                                            onChange={(event) => setPayLink(event.target.value)} />
                                    </div>

                                </div>

                            </div>
                            <div className="add-btn">
                                <button className="btn primary" onClick={addClient}>Add</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </Transition>
    )
}

export default ClientCreationModal;