import './AdminModal.css';
import '../Modal.css';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

function AdminModal({isOpen, setOpen, admin, clientID, setAdmins}) {
    const [adminInfo, setAdminInfo] = useState(admin ? admin : {
        Email: "",
        Username: "",
        RoleID: 3,
        Manages: clientID
    });

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState(null);

    const closeModal = () => {setOpen(false)};

    /**
     * A helper method to update a value in a react state object
     * @param {*} prop 
     * @param {*} value 
     */
    const updateInfo = (prop, value) => {
        let tempObj = JSON.parse(JSON.stringify(adminInfo));
        tempObj[prop] = value;
        setAdminInfo(tempObj);
    } 

    /**
     * Determines if passwords match
     * @returns Boolean
     */
    const passwordsMatch = () => {
        //TODO: Add regex if we create password requirements
        return password === confirmPassword;
    }

    /**
     * Attempt to update or create an admin
     */
    const saveNewInfo = () => {
        if(!passwordsMatch()) {
            // setError("Passwords do not match");
            toast.error("Passwords do not match");
            return;
        }

        let fetchBody = {
            Email: adminInfo.Email,
            Username: adminInfo.Username,
            UserId: adminInfo.UserId,
            RoleID: adminInfo.RoleID,
            Manages: clientID
        };
        // console.log(adminInfo.Manages, clientID, fetchBody);
        //If a password is typed in and it matches the confirm password, send to backend
        if(password.length > 0) fetchBody.Password = password;

        //update existing admin
        let accessToken = localStorage.getItem('accessToken');
        if(adminInfo.hasOwnProperty("UserId")) {
            fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/user/${fetchBody.UserId}`, {
                method: "PUT",
                mode: 'cors',
                credentials: "include",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization' : accessToken
                },
                body: JSON.stringify(fetchBody)
              }).then(res => res.json()).then(value => {
                if(value.error) {
                    //show error
                    // setError(value.error);
                    toast.error("An error occured");
                }
                else {
                    //successfully updated user
                    toast.success("Admin saved");
                    setAdmins((prev) => {
                        return updateAdminsState(prev, value);
                    })
                    closeModal();
                }
            });
        }
        //New admin
        else {
            console.log(fetchBody);
            let accessToken = localStorage.getItem('accessToken');
            fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/user`, {
                method: "POST",
                mode: 'cors',
                credentials: "include",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization' : accessToken
                },
                body: JSON.stringify(fetchBody)
              }).then(res => res.json(),[accessToken]).then(value => {
                if(value.error) {
                    //show error
                    setError(value.error);
                }
                else {
                     //successfully created user
                    setAdmins((prev) => {
                        let temp = prev;
                        temp.push(value);
                        return temp;
                    });
                    closeModal();
                    // console.log(value);
                }
               
            },[accessToken]);
        }
    }

    /**
     * Update specific admin in list of admins
     * @param {*} prev List of admins
     * @param {*} data New admin data
     * @returns Updated list of admins
     */
    const updateAdminsState = (prev, data) => {
        let temp = prev;
        for(let i = 0; i < temp.length; i++) {
            if(temp[i].UserId == data.UserId) {
                temp[i] = data;
                break;
            }
        }
        return temp;
    }

    /**
     * Update the admin level based on checkbox input
     * @param {String} type Type of admin
     * @param {Boolean} checked 
     */
    const updateAdminLevel = (type, checked) => {
        //should only see RoleIDs 2 and 3
        //2: super admin
        //3: site admin

        if(type === "site" && !checked) {
            updateInfo("RoleID", 4);
        }
        else if(type === "super" && !checked) {
            updateInfo("RoleID", 3);
        }
        else if(type === "super" && checked) {
            updateInfo("RoleID", 2);
        }
        else if(type === "site" && checked) {
            updateInfo("RoleID", 3);
        }
    }


    /**
     * When admin is updated, update admin state and reset password inputs.
     * This is necessary because this component is constantly alive.
     */
    useEffect(() => {
        setAdminInfo(admin ? admin : {
            Email: "",
            Username: "",
            RoleID: 3,
            Manages: clientID
        });
        setPassword("");
        setConfirmPassword("");
        setError(null);
    }, [admin]);

    if(isOpen) 
    return (
        <div className="admin-modal modal-container">
            <div className="screen" onClick={closeModal}></div>
            <div className="modal">
                <button className="close-modal-btn" onClick={closeModal}>✕</button>
                <div className="modal-header">
                    <h1>Site Admin</h1>
                    {/* <h2>{location}</h2> */}
                </div>
                <div className='modal-body'>
                    <div className='split-section'>
                        <div className='left-side'>
                            <div className='input-group'>
                                <label htmlFor="admin-username-input">Username</label>
                                <input id="admin-username-input" value={adminInfo?.Username} onChange={(event) => updateInfo("Username", event.target.value)} />
                            </div>
                            <div className='input-group'>
                                <label htmlFor="admin-email-input">Email Address</label>
                                <input id="admin-email-input" value={adminInfo?.Email} onChange={(event) => updateInfo("Email", event.target.value)} />
                            </div>
                        </div>
                        <div className='right-side'>
                            <div className='input-group'>
                                <label htmlFor="admin-password-input">Password</label>
                                <input type="password" id="admin-password-input" value={password} onChange={(event) => setPassword(event.target.value)} />
                            </div>
                            <div className='input-group'>
                                <label htmlFor="admin-confirm-password-input">Confirm Password</label>
                                <input type="password" id="admin-confirm-password-input" value={confirmPassword} onChange={(event) => setConfirmPassword(event.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className='admin-level-section'>
                        <p>Administrator</p>
                        <div>
                            <div className='input-group'>
                                <label htmlFor='super-admin-checkbox'>Super Administrator</label>
                                <input type="checkbox" id="super-admin-checkbox" checked={adminInfo?.RoleID <= 2} onChange={(event) => updateAdminLevel("super", event.target.checked)}/>
                            </div>
                            <div className='input-group'>
                                <label htmlFor='site-admin-checkbox'>Site Administrator</label>
                                <input type="checkbox" id="site-admin-checkbox" checked={adminInfo?.RoleID <= 3} onChange={(event) => updateAdminLevel("site", event.target.checked)}/>
                            </div>
                        </div>
                    </div>
                    {error ? <p className='error-msg center'>{error}</p> : <></>}
                    <button className='btn center primary' onClick={saveNewInfo}>Update</button>
                </div>
            </div>
        </div>

    );
    else return <></>;
}

export default AdminModal;