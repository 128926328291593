import './AdvancedSearchModal.css';
import '../Modal.css';
import { Transition } from "react-transition-group";

import { useEffect, useState } from 'react';

function AdvancedSearchModal({isOpen, setOpen, filters, setFilters, search, client_id}) {
    const [listData, setListData] = useState({});

    const closeModal = () => {setOpen(false)};
    

    /**
     * When the client_id is set, fetch the land and building types to display in the advanced filters
     */
    useEffect(() => {
        if(client_id)
        fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/landbuilding/${client_id}`)
        .then(res => res.json())
        .then(value => setListData(value));
    }, [client_id]);



    /**
     * If the land type is selected, add it to the filters object. If it is deselected, 
     * remove it from the object.
     * @param {String} input Selected land type
     */
    const toggleLandType = (input) => {
        let temp = JSON.parse(JSON.stringify(filters));
        // let temp = filters;
        //Found in list
        if(temp.land.type.indexOf(input) != -1) {
            //remove from list
            temp.land.type.splice(temp.land.type.indexOf(input), 1);
        }
        else {
            //add to list
            temp.land.type.push(input);
        }
        setFilters(temp);
    } 

    /**
     * If the building type is selected, add it to the filters object. If it is deselected, 
     * remove it from the object.
     * @param {String} input Selected building type
     */
    const toggleBuildingStyle = (input) => {
        let temp = JSON.parse(JSON.stringify(filters));
        // let temp = filters;
        //Found in list
        if(temp.building.style.indexOf(input) != -1) {
            //remove from list
            temp.building.style.splice(temp.building.style.indexOf(input), 1);
        }
        else {
            //add to list
            temp.building.style.push(input);
        }
        setFilters(temp);
    }   

    /**
     * If the ownership is selected, add it to the filters object. If it is deselected,
     * remove it from the object
     * @param {String} ownershipInput 
     */
    const toggleCommercialInput = ownershipInput => {
        let temp = JSON.parse(JSON.stringify(filters));
        // let temp = filters;
        //Found in list
        if(temp.building.ownership.indexOf(ownershipInput) != -1) {
            //remove from list
            temp.building.ownership.splice(temp.building.ownership.indexOf(ownershipInput), 1);
        }
        else {
            //add to list
            temp.building.ownership.push(ownershipInput);
        }
        setFilters(temp);
    }

    /**
     * Dynamically update react state object
     * @param {String} location Location of key. eg "parent.desiredKey"
     * @param {*} value Value to set in location
     */
    const updatePropInput = (location, value) => {
        let obj = JSON.parse(JSON.stringify(filters));
        let selectedLocation = obj;
        let path = location.split(".")
        for(let i = 0; i < path.length-1; i++) {
            selectedLocation = selectedLocation[path[i]];
            // console.log(selectedLocation);
        };
        selectedLocation[path[path.length-1]] = value;
        // console.log(selectedLocation);
        // console.log(obj);
        setFilters(obj);
    }

    /**
     * Determines if the input given appears in the list
     * @param {String} list String representation of what list to check against
     * @param {*} input The value to compare against 
     * @returns Boolean whether the value is in the list or not
     */
    const valueInList = (list, input) => {
        // console.log(filters.land.area, input);
        if(list === "landType") {
            return(filters.land.area.indexOf(input) !== -1);
        }
        else if(list === "buildingStyle") {
            return(filters.building.style.indexOf(input) !== -1);
        }
    }



    if(client_id) 
    return (
        <Transition in={isOpen} timeout={{ enter: 0, exit: 150 }} appear>
            {state => (
                <div className={`advanced-search-modal modal-container ${state}`}>
                    <div className="screen" onClick={closeModal}></div>
                    <div className="modal">
                        <button className="close-modal-btn" onClick={closeModal}>✕</button>
                        <h1>Advanced Search</h1>
                        <div className="sale-section modal-section">

                            <h2>Sale Data</h2>
                            <div className="sale-price input-group">
                                <label>Price:</label>
                                <input type="number" value={filters.sale.price.start} onInput={event => updatePropInput("sale.price.start", event.target.value)} />
                                <span>to</span>
                                <input type="number" value={filters.sale.price.end} onInput={event => updatePropInput("sale.price.end", event.target.value)} />
                            </div>
                            
                            <div className="sale-date input-group">
                                <label>Date:</label>
                                <input type="date" value={filters.sale.date.start} onInput={event => updatePropInput("sale.date.start", event.target.value)} />
                                <span>to</span>
                                <input type="date" value={filters.sale.date.end} onInput={event => updatePropInput("sale.date.end", event.target.value)} />
                            </div>
                        </div>

                        <div className="land-section modal-section">
                            <h2>Land</h2>
                            <div className="land-area input-group">
                                <label>Area:</label>
                                <input type="text" value={filters.land.area.start} onInput={event => updatePropInput("land.area.start", event.target.value)} />
                                <span>to</span>
                                <input type="text" value={filters.land.area.end} onInput={event => updatePropInput("land.area.end", event.target.value)} />
                            </div>
                            <div className="land-type input-group">
                                <label>Land Type:</label>
                                <div className="checkbox-container">
                                    {
                                        listData?.landType?.map((input, index) => {
                                            return(<div key={`land${index}`}><input type="checkbox" value={input} name="landType" id={input} checked={(filters.land.type.indexOf(input) !== -1)} onClick={() => toggleLandType(input)}/> <label htmlFor={input}>{input}</label></div>);
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="building-section modal-section">
                            <h2>Building</h2>
                            <div className="building-area input-group">
                                <label>Area:</label>
                                <input type="text" value={filters.building.area.start} onInput={event => updatePropInput("building.area.start", event.target.value)} />
                                <span>to</span>
                                <input type="text" value={filters.building.area.end} onInput={event => updatePropInput("building.area.end", event.target.value)} />
                            </div>
                            <div className="building-style input-group">
                                <label>Building Style:</label>
                                <div className="checkbox-container">
                                    {
                                        listData?.buildingStyle?.map((input, index) => {
                                            return(<div key={`building${index}`}><input type="checkbox" value={input} name="buildingStyle" id={input} checked={(filters.building.style.indexOf(input) !== -1)} onClick={() => toggleBuildingStyle(input)} /> <label htmlFor={input}>{input}</label></div>);
                                        })
                                    }
                                </div>
                            </div>
                            <div className="side-by-side">
                                <div className="bedroom-count input-group">
                                    <label>Bedrooms:</label>
                                    <input type="number" value={filters.building.bedrooms} onInput={event => updatePropInput("building.bedrooms", event.target.value)} />
                                    
                                </div>
                                <div className="bathroom-count input-group">
                                    <label>Bathrooms:</label>
                                    <input type="number" value={filters.building.bathrooms} onInput={event => updatePropInput("building.bathrooms", event.target.value)} />
                                    
                                </div>
                            </div>
                            <div className="ownership">
                                <label>Ownership:</label>
                                <div>
                                    <div>
                                        <input type="checkbox" name="ownership" value="commercial" id="Commercial" checked={(filters.building.ownership.indexOf("commercial") !== -1)} onClick={() => toggleCommercialInput("commercial")} />
                                        <label htmlFor="Commercial">Commercial</label>
                                    </div>

                                    <div>
                                        <input type="checkbox" name="ownership" value="residential" id="Residential" checked={(filters.building.ownership.indexOf("residential") !== -1)} onClick={() => toggleCommercialInput("residential")} />
                                        <label htmlFor="Residential">Residential</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <button className="btn center primary" onClick={() => {closeModal(); search(false,null,1);}}>Search</button>
                    </div>
                </div>
            )}
        
        </Transition>

    );
    else return <></>;
}

export default AdvancedSearchModal;