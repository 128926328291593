import './InfoModal.css';
import '../Modal.css';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Transition } from "react-transition-group";


function InfoModal({isOpen, setOpen, location, townInfo}) {
    const closeModal = () => {setOpen(false)};

// ContactUrl: "",
// HoursOfOperation: "",
// freeFormMessage: ""


    // if(isOpen) 
    return (
        <Transition in={isOpen} timeout={{ enter: 0, exit: 150 }} appear>
        {state => (
        <div className={`info-modal modal-container ${state}`}>
            <div className="screen" onClick={closeModal}></div>
            <div className="modal">
                <button className="close-modal-btn" onClick={closeModal}>✕</button>
                <div className="modal-header">
                    <h1>Contact Info</h1>
                    {/* <h2>{location}</h2> */}
                </div>
                <div className='modal-body'>
                    <Carousel showArrows={true} autoPlay={false} showStatus={false} showThumbs={false}>
                    {
                        townInfo?.map(info => {
                            return (
                            <div className='slide' key={info.ContactID}>
                                {
                                    info.Title ? <div className='data-field'>
                                        <h3>Title</h3>
                                        <p>{info.Title}</p>
                                    </div> : <></>
                                }
                                {
                                    info.ContactUrl ? <div className='data-field'>
                                        <h3>Home URL</h3>
                                        <a href={info.ContactUrl} target="_blank">{info.ContactUrl}</a>
                                    </div> : <></>
                                }
                                {
                                    info.HoursOfOperation ? <div className='data-field'>
                                        <h3>Hours of Operation</h3>
                                        <p>{info.HoursOfOperation}</p>
                                    </div> : <></>
                                }
                                {
                                    info.Telephone ? <div className='data-field'>
                                        <h3>Telephone</h3>
                                        <p>{info.Telephone}</p>
                                    </div> : <></>
                                }
                                {
                                    info.ContactEmail ? <div className='data-field'>
                                        <h3>Email</h3>
                                        <p>{info.ContactEmail}</p>
                                    </div> : <></>
                                }
                                {
                                    info.FreeFormMessage ? <div className='data-field'>
                                        <h3>Additional Info</h3>
                                        <p>{info.FreeFormMessage}</p>
                                    </div> : <></>
                                }
                            </div>
                            );
                        })
                    }
                    </Carousel>
                </div>
            </div>
        </div>
        )}
        
        </Transition>

    );
    // else return <></>;
}

export default InfoModal;