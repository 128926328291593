import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import './App.css';

function App() {
  //State 
  //[Getter, Setter] = useState(Default value)
  const [selectedState, setSelectedState] = useState("Maine");
  const [selectedCity, setSelectedCity] = useState("");
  const [isCitySelectionLocked, setCitySelectionLocked] = useState(true);
  const [isSearchLocked, setSearchLocked] = useState(true);
  const [redirect, setRedirect] = useState(null);

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  /**
   * useEffect is an event listener. It runs when selectedState (selected dependency) changes
   * Controls whether the search button and the city selection should be locked
   */
  useEffect(() => {
    if(selectedState) {
      if(isCitySelectionLocked) {
        setCitySelectionLocked(false);
        if(selectedCity) setSearchLocked(false);
        //Get list of cities for the selected state
        fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/${selectedState}/cities`).then(res => res.json()).then(value => setCityList(value?.sort()));
      }
    }
    else if(!isCitySelectionLocked) {
      setCitySelectionLocked(true);
      setSearchLocked(true);
    }
  }, [selectedState]);

  /**
   * Event listener: run when selectedCity (selected dependency) changes
   * Controls whether the search button should be locked
   */
  useEffect(() => {
    if(selectedCity) {
      if(isSearchLocked) setSearchLocked(false);
    }
    else if(!isSearchLocked) setSearchLocked(true);
  }, [selectedCity]);

  /**
   * Event listener: run once on page load
   */
  useEffect(() => {
    //Get list of states from backend
    fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/states`).then(res => res.json()).then(value => setStateList(value));
  }, []);


  /**
   * Calls search method if the Enter key was pressed
   * @param {*} event Keyboard event
   */
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') search();
  }

  /**
   * Callback method tied to the search button click
   */
  const search = () => {
    // console.log(selectedState, selectedCity);
    setRedirect(`/${selectedState}/${selectedCity}/search`);
  }

  //Rendered jsx
  if(redirect) return <Navigate to={redirect} />
  return (
    
    <div className="home-page">
      <header>
        <h1>Harris Online Property Portal</h1>        
      </header>
      <div className='main-section'>
        <div className='find-a-property'>
          <h2>Find A Property</h2>
          <div className='input-group'>
            <label htmlFor="state-select">Region</label>
            {/* Datalist option. Allows users to type in towns and see the option appear as a suggestion */}
            {/* <input id="state-select" list="state-list" autoComplete="off" value={selectedState} onChange={(event) => setSelectedState(event.target.value)} />
            <datalist id="state-list">
              <option value="" />
              {
                stateList.map((input, index) => {
                    return(<option key={`state${index}`} value={input}/>);
                })
              }
            </datalist> */}
            {/* Select option. Enforces one of the following options to be selected */}
            <select id="state-select" autoComplete="off" value={selectedState} onChange={(event) => setSelectedState(event.target.value)}>
              <option value="" />
              {
                stateList.map((input, index) => {
                    return(<option key={`state${index}`} value={input}>{input}</option>);
                })
              }
            </select>


          </div>
          <div className='input-group'>
            <label htmlFor="city-select">Community</label>
            {/* Datalist option. Allows users to type in towns and see the option appear as a suggestion */}
            {/* <input id="city-select" list="city-list" autoComplete="off" value={selectedCity} onChange={(event) => setSelectedCity(event.target.value)} onKeyDown={handleKeyDown} disabled={isCitySelectionLocked}/>
            <datalist id="city-list">
              <option value="" />
              {
                cityList.map((input, index) => {
                    return(<option key={`city${index}`} value={input}/>);
                })
              }
            </datalist> */}

            {/* Select option. Enforces one of the following options to be selected */}
            <select id="city-select" autoComplete="off" value={selectedCity} onChange={(event) => setSelectedCity(event.target.value)} onKeyDown={handleKeyDown} disabled={isCitySelectionLocked}>
              <option value="" />
              {
                cityList.map((input, index) => {
                    return(<option key={`city${index}`} value={input}>{input}</option>);
                })
              }
            </select>
          </div>
          <button className='btn center primary' onClick={() => search()} disabled={isSearchLocked}>Search</button>
        </div>
      </div>
      <footer>
        <p>© Copyright Harris Local Government. All rights reserved. </p>
      </footer>
    </div>
  );
}

export default App;
