import { useContext, useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import './ClientAdmin.css';
import uploadIcon from "../../icons/upload-icon.svg";
import backArrowIcon from "../../icons/back-arrow-icon.svg";
import ContactList from '../../partials/ContactList/ContactList';
import SiteAdminList from '../../partials/SiteAdminList/SiteAdminList';
import ReactTooltip from 'react-tooltip';
import toast, { Toaster } from 'react-hot-toast';
import abbrState from "../../stateToAbbr.js";
import { UserContext } from "../../hooks/useContext";

function ClientAdminMain() {
    const [user, setUser] = useContext(UserContext);
    const [title, setTitle] = useState("Town of");
    const [name, setName] = useState("");
    const [originalBanner, setOriginalBanner] = useState("");
    const [banner, setBanner] = useState("");
    const [clientID, setClientID] = useState("");
    const [info, setInfo] = useState({});
    let params = useParams();
    const navigate = useNavigate();


    /**
     * Fetch and store client data
     */
    useEffect(() => {
        const fetchData = async () => {
        let clientId;
        //fetch client data. Set appropriate state from data.
        const client = await fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/client/${params.state}/${params.city}`);
        if (!client)
        {
          window.location.replace('/')
          window.alert("Unable to find municipality")        
        }
        const json = await client.json();
        clientId = json.ClientID;
        if (!clientId)
        {
            window.location.replace('/')
          window.alert("Unable to find municipality")        
        }
        setClientID(clientId);
        const clientInfo = await fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/client/${clientId}`);
        const infoJson = await clientInfo.json();
        console.log(infoJson);
        setTitle("");
        setName(infoJson?.City);
        if(infoJson.BannerImage) {
            setBanner({name: `banner-${infoJson?.BannerImage}`, src: `${process.env.REACT_APP_BACKEND_ORIGIN}/api/files/image/${infoJson?.BannerImage}`}); 
            setOriginalBanner(infoJson.BannerImage);
        }
        setInfo(infoJson);
        }
        fetchData().catch(e => console.log(e));
    }, [])

    /**
     * Log user out
     */
    const logout = () => {
        fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/logout`, {
            method: "POST",
            credentials: "include",
          }).then(() => {
            setUser(null);
            localStorage.setItem('UserId',null);
            localStorage.setItem('accessToken',null);
        });
    }
    
    /**
     * Remove banner image
     */
    const removeImage = () => {
        setBanner({});
    }

    /**
     * Save client data
     * @returns navigation to admin page
     */
    const saveClientData = async () => {
        //Make sure user manages the client or is a harris admin
        if(user.Manages !== null && user.Manages !== clientID) {
            toast.error("An error occured");
            return;
        }

        const fetchBody = {
            City: name,
            Description: `${title} ${name}`
        }

        if(banner.blob) {
            // const image = {
            //     file: new File(
            //         [banner.blob], // The uploaded file blob
            //         banner.name // The uploaded file's name 
            //     ),
            //     ClientID: clientID
            // }

            const image = new FormData();
            image.append('file', new File(
                        [banner.blob], // The uploaded file blob
                        banner.name // The uploaded file's name 
                    ));
            image.append('ClientID', clientID);
            image.append('fileType', banner.blob.type);
            let accessToken = localStorage.getItem('accessToken');
            if(originalBanner) {
               
                let imageData = await fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/image/${originalBanner}`, {
                    method: "PATCH",
                    credentials: "include",
                    headers: {
                    'Accept': 'application/json',
                    'Authorization' : accessToken
                    },
                    body: image
                });
            }
            else {
                console.log("asds");
                let imageData = await fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/image`, {
                    method: "POST",
                    credentials: "include",
                    headers: {
                    'Accept': 'application/json',
                    'Authorization' : accessToken
                    },
                    body: image
                });
            }
            // let jsonData = await imageData.json();
            // console.log(jsonData);
            // fetchBody.BannerImage = jsonData.ImageID; 
        }
        else {
            //Remove banner image
            if(!banner.src) {
                let accessToken = localStorage.getItem('accessToken');
                let imageData = await fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/image/${originalBanner}`, {
                    method: "DELETE",
                    credentials: "include",
                    headers: {
                        'Accept': 'application/json',
                        'Authorization' : accessToken
                        }
                });
            }
        }
        let accessToken = localStorage.getItem('accessToken');
        fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/client/${clientID}`, {
            method: "PATCH",
            credentials: "include",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization' : accessToken
            },
            body: JSON.stringify(fetchBody)
          }).then(res => res.json()).then(value => {
            if(value.error) {
                //show error
                // setError(value.error);
                console.log(value);
                toast.error("An error occured");
            }
            else {
                //successfully updated 
                toast.success("Client saved");
                if(name !== params.City) return navigate(`/${abbrState(info.StateProvince, "name")}/${name}/admin`);
            }
           
        });
    }

    return (
        <div className="client-admin-page">
            <div><Toaster/></div>
            <div className="header-section">
            <Link to="/"><h1>Harris Online Property Portal</h1></Link>
                <div className='user'>
                    <p>{user?.Username}</p>
                    <div className='dropdown'>
                        <button className='dropdown-item' onClick={logout}>Log out</button>
                    </div>
                </div>
            </div>
            <div className="form-section shadow">
                <Link className='link return-link' to={`/${params.state}/${params.city}/search`}><img src={backArrowIcon} />Return to search</Link>

                <h2>{`${params.city}, ${params.state}`}</h2>

                <div className="client-input-section">
                    <div className='left-side'>
                        <div className='input-group'>
                            <label htmlFor="title-input">Title</label>
                            <select id="title-input" value={title} onChange={(event) => setTitle(event.target.value)} >
                                <option value="Town of">Town of</option>
                            </select>
                        </div>
                        <div className='input-group'>
                            <label htmlFor="name-input">City/Town</label>
                            <input type="name" id="name-input" autoComplete="off" value={name} onChange={(event) => setName(event.target.value)} />
                        </div>
                    </div>
                    <div className='right-side'>
                        <div className='input-group'>
                            <label>Banner Image</label>
                            <div className='file-input'>
                                <div className={`file-control-container ${banner.blob || banner.src ? 'has-image' : ''}`}>
                                    <label className={`file-input-label`} htmlFor="banner-input" data-background-color="#8781a1" data-tip="Upload Banner" data-for="uploadbtn"><img src={uploadIcon}/></label>
                                    <ReactTooltip id="uploadbtn" place="top" type="info" effect="solid" />
                                    <ReactTooltip id="removefilebtn" place="top" type="info" effect="solid" />
                                    <button className='remove-file-btn' data-background-color="#8781a1" data-tip="Remove Image" data-for="removefilebtn" onClick={removeImage}>✕</button>
                                </div>
                                <img className='preview-banner' src={banner.src ? banner.src: banner.blob ? URL.createObjectURL(banner?.blob) : null} />
                                <p>{banner ? banner?.name?.includes("C:\\fakepath\\") ? banner?.name?.split("C:\\fakepath\\")[1] : banner?.name : null}</p>
                                <input type="file" id="banner-input" autoComplete="off" onChange={(event) => {setBanner({name: event.target.value, blob: event.target.files[0]})}} accept="image/png, image/jpeg, image/jpg" />
                            </div>
                        </div>
                    </div>
                    <div className="save-btn-container"><button className='btn center primary' onClick={saveClientData}>Save</button></div>
                </div>
                <hr />
                <ContactList />
                <hr />
                <SiteAdminList />
                {/* <button className='btn center primary' onClick={saveClientData}>Save</button> */}
            </div>
        </div>
    );
}


export default ClientAdminMain;