import React, { createContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter, Route, Routes, Navigate, useSearchParams } from "react-router-dom";

import { UserContext } from "./hooks/useContext";

// Pages
import App from "./pages/home/App";
import Search from "./pages/search/Search";
import Listing from "./pages/listing/Listing";

import ErrorBoundary from './partials/ErrorBoundary/ErrorBoundary.jsx';

import ClientAdmin from "./pages/client-admin/ClientAdmin";
import HarrisAdmin from "./pages/harrisadminclient/HarrisAdmin";


const Main = () => {


const [user, setUser] = useState(null);

let accessToken = localStorage.getItem('accessToken');
let UserId = localStorage.getItem('UserId');

// Try to get user credentials if they are logged in
 
useEffect(() => {
  fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/user/${UserId}`, {
    credentials: "include",
    headers: {
        'Accept': 'application/json',
        'Authorization' : accessToken
        }
    
  })
  .then(res => res.json())
  .then((data) => {
    if(!data?.error) setUser(data);
  }).catch(e => {
  });
}, []);


return (
  <React.StrictMode>
    <UserContext.Provider value={[user, setUser]}>
    <ErrorBoundary>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/admin" element={<HarrisAdmin harrisAdmin={true} />} />
        <Route path=":state/:city/search" element={<Search />} />
        <Route path=":state/:city/admin" element={<ClientAdmin />} />
        <Route path=":state/:city/:account" element={<Listing />} />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </BrowserRouter>
    </ErrorBoundary>
    </UserContext.Provider>
  </React.StrictMode>
);

}

export default Main;