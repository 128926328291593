import './Search.css';
import { useParams, Link } from "react-router-dom";
import magnifyingGlass from "../../icons/magnifying-glass-solid.svg";
import infoIcon from "../../icons/circle-info.svg";
import filterIcon from "../../icons/filter-icon.svg";
import sortIcon from "../../icons/arrows-sort-icon.svg";
import clearFilterIcon from "../../icons/clear-filter-icon.svg";
import { useEffect, useState } from 'react';
import AdvancedSearchModal from '../../partials/AdvancedSearchModal/AdvancedSearchModal';
import ReactTooltip from 'react-tooltip';
import InfoModal from '../../partials/InfoModal/InfoModal';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {formatDate} from '../../utils/DateFormat';

const defaultPageSize = 50;
function Search() {
  let params = useParams();
  const [isAdvancedSearchModalOpen, setAdvancedSearchModalOpen] = useState(false); 
  const [isInfoModalOpen, setInfoModalOpen] = useState(false); 
  const [townIDImage, setTownIDImage] = useState();
  const [searchQuery, setSearchQuery] = useState(""); // Owner name, Map Lot ID, Location, Account Number
  const [viewStyle, setViewStyle] = useState("list");
  const [searchResults, setSearchResults] = useState([]);
  const [baseImageURL, setBaseImageURL] = useState(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/files/image/`);
  const [townInformation, setTownInformation] = useState({});
  const [filtersClear, setFiltersClear] = useState(false);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [listGridView, setListGridView] = useState();

  const [property, setProperty] = useState({});

  const [showMobileFilters, setShowMobileFilters] = useState(false);

  const [currentPage, setCurrentPage] = useState(1); 
  const [propPerPage,setPropPerPage] = useState(defaultPageSize);  
  const [totalPages,setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [clientId, setClientId] = useState();
  const [filters, setFilters] = useState({
    sale: {
        price: {
            start: "",
            end: ""
        },
        date: {
            start: "",
            end: ""
        }
    },
    land: {
        area: {
            start: "",
            end: ""
        },
        type: []
    },
    building: {
        area: {
            start: "",
            end: ""
        },
        style: [],
        bedrooms: "",
        bathrooms: "",
        ownership: []
    }
});

const [sortConfig,setSortConfig] = useState({
  descending: false,
  sortby: "name"
});

  //off, descending, ascending
  const [sorts, setSorts] = useState({
    Maplot: {direction: "off", priority: -1},
    Name: {direction: "off", priority: -1},
    Location: {direction: "off", priority: -1},
    Assessment: {direction: "off", priority: -1},
    Acreage: {direction: "off", priority: -1},
    SaleDate: {direction: "off", priority: -1},
    SalePrice: {direction: "off", priority: -1}
  });
  const [townInfo, setTownInfo] = useState();

  const getDefaultSortState = () =>  {
    return {
      Maplot: {direction: "off", priority: -1},
      Name: {direction: "off", priority: -1},
      Location: {direction: "off", priority: -1},
      Assessment: {direction: "off", priority: -1},
      Acreage: {direction: "off", priority: -1},
      SaleDate: {direction: "off", priority: -1},
      SalePrice: {direction: "off", priority: -1}
    };
  }

  const gotoLastPage = () => {
    if (canNavigateLast()){
      gotoPage(getLastPageNumber());
    }
  }

  const gotoFirstPage = () => {
    if (canNavigateFirst()){
      gotoPage(getFirstPageNumber());
    }
  }

  const gotoNextPage = () => {
    if (canNavigateNext()){
      gotoPage(getNextPageNumber());
    }
  };

  const gotoPreviousPage = () => {
    if (canNavigatePrevious()){
      gotoPage(getPreviousPageNumber());
    }
  };

  const gotoPage = (newPageNumber) => {
    if (pageExists(newPageNumber)){      
      setCurrentPage(newPageNumber);
      search(false,null,newPageNumber);
    } else {
      setCurrentPage(pageNumber);      
    }
  };
  
  const change = (event) => {
    setPageNumber(event.target.value)
  }

  const pageExists = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages)
    {
      return true;
    }
    return false;
  };
  
  const canNavigateLast = () => {
    return totalPages;
  }

  const canNavigateFirst = () => {
    return 1;
  }

  const canNavigateNext = () => {
    return currentPage < totalPages;
  };

  const canNavigatePrevious = () => {
    return currentPage > 1;
  };

  const getPreviousPageNumber = () => {
    let newPage = currentPage - 1;
    if (newPage < 1){
      newPage = 1;
    }
    return newPage
  }

  const getLastPageNumber = () => {
    let newPage = totalPages;
    if (newPage > totalPages){
      newPage = totalPages;
    }
    return newPage;
  }

  const getFirstPageNumber = () => {
    let newPage = 1;
    if (newPage < 1){
      newPage = 1;
    }
    return newPage
  }

  const getNextPageNumber = () => {
    let newPage = currentPage + 1;
    if (newPage > totalPages){
      newPage = totalPages;
    }
    return newPage;
  }

  const getShowingText = (recordCount,recordsPerPage,currPage) => {
   
    let startRecordIndex = (currPage - 1) * recordsPerPage + 1;
    let endIndex = startRecordIndex + recordCount - 1;
    return (startRecordIndex || 0).toString() + " to " + (endIndex || 0).toString();
  }

  /**
   * Get the value of data based on the key provided
   * @param {Object} data Search result data 
   * @param {*} key Data key
   * @returns Value from data based on the key provided
   */
  const getDataFieldByKey = (data, key) => {
    switch(key) {
      case "Maplot": return data?.MapLot;
      case "Name": return data?.OwnerName;
      case "Location": return `${data?.StreetNumber} ${data?.StreetName}`;
      case "Assessment": return data?.BuildingValue + data?.LandValue;
      case "Acreage": return data?.TotalAcres;
      case "SaleDate": return data?.SaleDate;
      case "SalePrice": return data?.SalePrice;
    }
  }


  /**
   * Sort results with sort object 
   */

  /*
  useEffect(() => {
    try{
    let tempResults = JSON.parse(JSON.stringify(searchResults));
    
   
    setSearchResults(tempResults);
    }catch (error) {
      console.error(error)
    }
  }, [sorts]);
*/

  /**
   * Get result links
   * @returns An array of result urls
   */
  const getResultUrls = () => {
    return searchResults?.SearchProperties?.map(s => `${params.state}/${params.city}/${s.Account}`);
  }

  /**
   * Set results list to local storage 
   */
  useEffect(() => {
    //Time to live in milliseconds
    let ttl = 604800000; //7 days
    localStorage.setItem(`${params.state}-${params.city}-results`, JSON.stringify({
      results: getResultUrls(),
      ttl: (Date.now() + ttl)
    }));

  }, [searchResults?.SearchProperties]);

  /**
   * Return the string to display based on the direction of the sort
   * @param {String} sort String value of sort
   * @returns String to display
   */
  const getSortDisplay = (sort) => {
    switch(sorts[sort].direction) {
      case "off":
        return "&#9650; &#9660;";
      case "descending":
        return "&nbsp; &#9660;";
      case "ascending":
        return "&#9650;";
            
    }
  }


  const sortEm = (sortField) => {
    var oldConfig = sortConfig;
    let sortDescending = false;

    try{
    if (oldConfig && oldConfig.sortby == sortField){
      sortDescending = !oldConfig.descending;
    } 
    let newSortState = getDefaultSortState();
    if (sortDescending){      
      newSortState[sortField].direction = "descending";
    } else {
      newSortState[sortField].direction = "ascending";
    }

    setSorts(newSortState);
    let sortConfiguration = {descending: sortDescending, sortby: sortField};
    setSortConfig({descending: sortDescending, sortby: sortField});
    search(false,sortConfiguration,1);
  } catch (ex){

  }

  };

  const makeQuery = (targetPageNumber = 0) => {
    let tempQuery = "&page=";
    if (targetPageNumber){
      tempQuery = tempQuery + targetPageNumber.toString();
    } else if (currentPage) {
      tempQuery = tempQuery + currentPage.toString();
    } else {
      tempQuery = tempQuery + "1";
    }
    if (propPerPage)
    {
      tempQuery = tempQuery + "&pagesize=" + propPerPage.toString();
    } else {
      tempQuery = tempQuery + "&pagesize=" + defaultPageSize.toString();
    }
    return tempQuery;
  };

  const clearFilters = () => {
    setSearchQuery("");
    setFilters({
      sale: {
          price: {
              start: "",
              end: ""
          },
          date: {
              start: "",
              end: ""
          }
      },
      land: {
          area: {
              start: "",
              end: ""
          },
          type: []
      },
      building: {
          area: {
              start: "",
              end: ""
          },
          style: [],
          bedrooms: "",
          bathrooms: "",
          ownership: []
      }
    });
    //off, descending, ascending
    setSorts({
      Maplot: {direction: "off", priority: -1},
      Name: {direction: "off", priority: -1},
      Location: {direction: "off", priority: -1},
      Assessment: {direction: "off", priority: -1},
      Acreage: {direction: "off", priority: -1},
      SaleDate: {direction: "off", priority: -1},
      SalePrice: {direction: "off", priority: -1}
    });
    setSortConfig({descending: false, sortby: 'Name'});
    //After clearing filters, show new results
    setFiltersClear(true);
  }


  const openAdvancedSearchModal = () => {setAdvancedSearchModalOpen(true);}
  const openInfoModal = () => {setInfoModalOpen(true);}

  /**
   * Search for properties based on filters and search query
   * @param {Boolean} hideSkeleton Determine if the skeleton loading should hide 
   */
  const search = (hideSkeleton = false,sortConfiguration = null,targetPageNumber = 1) => {
    
    let sortCon = sortConfig;
    if (sortConfiguration){
      sortCon = sortConfiguration;
    }    
     fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/propertysearch/${clientId}?query=${searchQuery}${makeQuery(targetPageNumber)}`, {
      method: "POST",
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({filters: filters,sort: sortCon})
    }).then(res => res.json()).then(value => {
      setPropPerPage(value?.PageSize);
      setPageNumber(value?.PageNumber);
      setCurrentPage(value?.PageNumber);
      setSearchResults(value); 
      setTotalPages ( value?.Pages);
      setTotalRecords( value?.TotalRecords);
      if(hideSkeleton) setLoading(false);
    });
  }

  

  /**
   * After clearing filters, show new results
   */
  useEffect(() => {
    if(filtersClear) {
      search();
      setFiltersClear(false);
    }
  }, [filtersClear]);


  /**
   * On page load, fetch client id and get data out of localstorage 
   */
  useEffect(() => {
    
    //get client id
    //TODO: redirect if no client id is returned (no town exists with that state and city provided)
    fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/client/${params.state}/${params.city}`).then(res => res.json()).then(value => 
      {
        setClientId(value?.ClientID);
        if (!value?.ClientID)
        {      
          window.location.replace('/')
          window.alert("Unable to find municipality")          
        }
      });

    

    //Get searchQuery, filters, and sorts from localstorage
    let storage = JSON.parse(localStorage.getItem(`${params.state}-${params.city}`));
    if(storage) {
      //Within ttl
      if(storage.ttl && storage.ttl >= Date.now()) {
        setSearchQuery(storage.searchQuery);
        if (storage.filters.land.area.start == 0 && storage.filters.land.area.end == 0)
        {
          storage.filters.land.area.start = "";
          storage.filters.land.area.end = "";
        }
        if (storage.filters.building.bathrooms == 0)
        {
          storage.filters.building.bathrooms = "";
        }
        if (storage.filters.building.bedrooms == 0)
        {
          storage.filters.building.bedrooms = "";
        }
        if (storage.filters.sale.price.start == 0 && storage.filters.sale.price.end == 0)
        {
          storage.filters.sale.price.start = "";
          storage.filters.sale.price.end = "";
        }
        setFilters(storage.filters);
        
        setSorts(storage.sorts);
        setSortConfig(storage.sortConfig);
        setViewStyle(storage.viewStyle);
      }
      //ttl is expired: delete data
      else {
        localStorage.removeItem(`${params.state}-${params.city}`);
      }

    }

    // let storage_filters = JSON.parse(localStorage.getItem('filters'));
    // if(storage_filters) setFilters(storage_filters);

    // let storage_sorts = JSON.parse(localStorage.getItem('sorts'));
    // if(storage_sorts) setSorts(storage_sorts);

  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  /**
   * When the client id is set, fetch town information
   */
  useEffect(() => {
    if(clientId) {
      fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/client/${clientId}`).then(res => res.json()).then(value => {
        setTownInformation(value);
        if(value.BannerImage) setTownIDImage(`${baseImageURL}${value.BannerImage}`);

      });
      
      //query data for town info on page load and set data to setTownInfo
      fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/contacts/${clientId}`).then(res => res.json()).then(value => setTownInfo(value));
      search(true);
      
    } 
  }, [clientId]);



  // useEffect(() => {
  //   localStorage.setItem('searchQuery', JSON.stringify(searchQuery));
  // }, [searchQuery]);
  // useEffect(() => {
  //   localStorage.setItem('filters', JSON.stringify(filters));
  // }, [filters]);
  // useEffect(() => {
  //   localStorage.setItem('sorts', JSON.stringify(sorts));
  // }, [sorts]);

  /**
   * Call search when Enter key is pressed
   * @param {*} event Keyboard event
   */
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      gotoPage( event.target.value);
    } 
  }

  const handleSearchKeyDown = (event) => {
    if (event.key === 'Enter') {
      search({targetPageNumber: 1});      
    } 
  }

  /**
   * When searchQuery, filters, or sorts are set, update localstorage
   */
  useEffect(() => {
    //Time to live in milliseconds
    let ttl = 604800000; //7 days
    localStorage.setItem(`${params.state}-${params.city}`, JSON.stringify({
      searchQuery: searchQuery,
      filters: filters,
      sorts: sorts,
      ttl: (Date.now() + ttl),
      viewStyle: viewStyle
    }));
  }, [searchQuery, filters, sorts, viewStyle]);

  return (
    <>
    <div className="search-page">
      <div className="header-section">
        <Link to="/"><h1>Harris Online Property Portal</h1></Link>
            <div className='user'>
                <Link to={`/${params.state}/${params.city}/admin`}>Admin</Link>
            </div>
        </div>

      <div className='results-section'>
        <div className="search-container">
        <div className='town-id'>{townIDImage ?<img className='banner-image' src={townIDImage}/> : <h2>{params.city}, {params.state}</h2> }<img onClick={openInfoModal} className="info-icon" src={infoIcon} data-tip="Town Info" data-for="towninfo"  data-background-color="#8781a1"/> <ReactTooltip id="towninfo" place="right" type="info" effect="solid"  /></div>
          <div className="inline-search-field">
            <div className="input-bar">
              <input type="text" value={searchQuery} onInput={e => setSearchQuery(e.target.value)} onKeyDown={handleSearchKeyDown} />
              <button  className="search-btn" onClick={() => search()} data-background-color="#8781a1" data-tip="Search" data-for="searchbtn"><img src={magnifyingGlass} className="magnifying-glass-icon"/></button>
              <ReactTooltip id="searchbtn" place="top" type="info" effect="solid" />
            </div>
              <div className='search-control-buttons'>
                <button data-tip="Advanced Search" data-for="AdvancedSearch" data-background-color="#8781a1"  className="toggle-advanced-params" onClick={openAdvancedSearchModal}><img src={filterIcon} /></button>
                <ReactTooltip id="AdvancedSearch" place="top" type="info" effect="solid" />
                <button data-tip="Clear Filters" data-for="ClearFilter" data-background-color="#8781a1"  className="clear-filters-button" onClick={clearFilters}><img src={clearFilterIcon} /></button>
                <ReactTooltip id="ClearFilter" place="top" type="info" effect="solid" />
              </div>
          </div>
        </div>
        <div className="section">
          <div className='container'>
            <div className="results-header">
              <h3>Showing {getShowingText(searchResults?.SearchProperties?.length || 0, searchResults?.PageSize,searchResults?.PageNumber)} of {totalRecords} results</h3>
              <div>
                <button className="toggle-show-sorts" onClick={() => {setShowMobileFilters(true)}}><img src={sortIcon} /></button>
                <div className="btn-group">
                  <button className={viewStyle === "list" ? "selected" : ""} onClick={() => setViewStyle("list")}>List</button>
                  <button className={viewStyle === "grid" ? "selected" : ""} onClick={() => setViewStyle("grid")}>Grid</button>
                </div>                
              </div>
            </div>
            <div className="results-container">
              <div className={`data-header ${showMobileFilters ? "" : "mobile-hidden" }`}>
                <div className='screen' onClick={() => {setShowMobileFilters(false)}}></div>
                <div className="data-header-desktop">
                  <h3 className='mobile-only'>Sorts</h3>
                  <button className="mobile-only close-modal-btn" onClick={ () => setShowMobileFilters(false)}>✕</button>
                  <div>
                    <div onClick={() => {sortEm("Maplot")}}>
                      <span>Map/Lot</span><span className='sort-icon' dangerouslySetInnerHTML={{__html: getSortDisplay("Maplot")}} ></span>
                    </div>
                  </div>
                  <div className="name">
                    <div onClick={() => {sortEm("Name")}}>
                      <span>Name</span><span className='sort-icon' dangerouslySetInnerHTML={{__html: getSortDisplay("Name")}} ></span>
                    </div>
                    <div onClick={() => {sortEm("Location")}}>
                      <span>Location</span><span className='sort-icon' dangerouslySetInnerHTML={{__html: getSortDisplay("Location")}}></span>
                    </div>
                  </div>

                  <div className="data-header-numbers">
                    <div onClick={() => {sortEm("Assessment")}}>
                      <span>Building + Land</span><span className='sort-icon' dangerouslySetInnerHTML={{__html: getSortDisplay("Assessment")}}></span>
                    </div>
                    <div onClick={() => {sortEm("Acreage")}}>
                      <span>Acres</span><span className='sort-icon' dangerouslySetInnerHTML={{__html: getSortDisplay("Acreage")}}></span>
                    </div>
                    <div onClick={() => {sortEm("SaleDate")}}>
                      <span>Last Sale Date</span><span className='sort-icon' dangerouslySetInnerHTML={{__html: getSortDisplay("SaleDate")}}></span>
                    </div>
                    <div onClick={() => {sortEm("SalePrice")}}>
                      <span>Last Sale Price</span><span className='sort-icon' dangerouslySetInnerHTML={{__html: getSortDisplay("SalePrice")}}></span>
                    </div>
                  </div>
                </div>

                
              </div>
              
              <div className={`results ${viewStyle}`}>
                {/* Skeleton loading */}
                {loading && (
                      <>
                        <div className='property-skeleton'> 
                        
                          <div className="thumbnail-image">
                            <Skeleton height="100%"/>
                          </div>
                          <div className="maplot">
                            <div><span className="label">Map/Lot</span><span className='data'><Skeleton /></span></div>
                          </div>
                          <div className="name">
                            <Skeleton height="1.8rem" style={{marginBottom: "1rem"}} />
                            <Skeleton height="1.2rem"/>
                          </div>
                          <div className="data-numbers">
                            <div><span className="label">Building + Land</span><span className='data'><Skeleton /></span></div>
                            <div><span className="label">Acres</span><span className='data'><Skeleton /></span></div>
                            <div><span className="label">Last Sale Date</span><span className='data'><Skeleton /></span></div>
                            <div><span className="label">Last Sale Price</span><span className='data'><Skeleton /></span></div>
                          </div>
                        </div>
                        <div className='property-skeleton'> 
                        
                          <div className="thumbnail-image">
                            <Skeleton height="100%"/>
                          </div>
                          <div className="maplot">
                            <div><span className="label">Map/Lot</span><span className='data'><Skeleton /></span></div>
                          </div>
                          <div className="name">
                            <Skeleton height="1.8rem" style={{marginBottom: "1rem"}} />
                            <Skeleton height="1.2rem"/>
                          </div>
                          <div className="data-numbers">
                            <div><span className="label">Building + Land</span><span className='data'><Skeleton /></span></div>
                            <div><span className="label">Acres</span><span className='data'><Skeleton /></span></div>
                            <div><span className="label">Last Sale Date</span><span className='data'><Skeleton /></span></div>
                            <div><span className="label">Last Sale Price</span><span className='data'><Skeleton /></span></div>
                          </div>
                        </div>
                        <div className='property-skeleton'> 
                        
                          <div className="thumbnail-image">
                            <Skeleton height="100%"/>
                          </div>
                          <div className="maplot">
                            <div><span className="label">Map/Lot</span><span className='data'><Skeleton /></span></div>
                          </div>
                          <div className="name">
                            <Skeleton height="1.8rem" style={{marginBottom: "1rem"}} />
                            <Skeleton height="1.2rem"/>
                          </div>
                          <div className="data-numbers">
                            <div><span className="label">Building + Land</span><span className='data'><Skeleton /></span></div>
                            <div><span className="label">Acres</span><span className='data'><Skeleton /></span></div>
                            <div><span className="label">Last Sale Date</span><span className='data'><Skeleton /></span></div>
                            <div><span className="label">Last Sale Price</span><span className='data'><Skeleton /></span></div>
                          </div>
                        </div>
                      </>
                    )}
                {
                  Array.isArray(searchResults?.SearchProperties) ? searchResults?.SearchProperties?.map((value, index) => {
                    return (
                      <Link to={`/${params.state}/${params.city}/${value.Account}`} className="property" key={`result${index}`} >
                        <div className="thumbnail-image">
                          <img src={(value.ImageURL) ? `${process.env.REACT_APP_BACKEND_ORIGIN}` + value.ImageURL : `${process.env.PUBLIC_URL}` + '/images/house2.jpg'} />
                        </div>
                        <div className="maplot">
                          <div><span className="label">Map/Lot</span><span className='data'>{value?.MapLot}</span></div>
                        </div>
                        <div className="name">
                          <h4>{value.Owners[0]}</h4>
                          <h4>{value.Owners[1]}</h4>
                          <p>{value.StreetNumber} {!value.Apartment?.includes('-') ? <> {value.Apartment}</> : value.Apartment} {value.StreetName}</p>
                        </div>
                        <div className="data-numbers">
                          <div><span className="label">Building + Land</span><span className='data'>${(value.TotalBuildingValue + value.LandValue).toLocaleString(undefined, { maximumFractionDigits: 2 })}</span></div>
                          <div><span className="label">Acres</span><span className='data'>{value.TotalAcres}</span></div>
                         <div><span className="label">Last Sale Date</span><span className='data'>{value?.SaleDate && formatDate(value.SaleDate)}</span></div>
                          <div><span className="label">Last Sale Price</span><span className='data'>{value?.SalePrice > 0 && '$' + value.SalePrice}</span></div> 
                          
                        </div>
                      </Link>
                    );
                }) : <></>
                }

                {/* <div className="property">
                  <div className="thumbnail-image">
                    <img src="http://reonline.harriscomputer.com/getpicture.aspx?ID=954707" />
                  </div>
                  <div className="maplot">
                    <div><span className="label">Map/Lot</span><span className='data'>08-01-15</span></div>
                  </div>
                  <div className="name">
                    <h4>Katrina L Abel</h4>
                    <p>189 Hanscom Rd</p>
                  </div>
                  <div className="data-numbers">
                    <div><span className="label">Building + Land</span><span className='data'>53,090</span></div>
                    <div><span className="label">Acres</span><span className='data'>1.97</span></div>
                    <div><span className="label">Last Sale Date</span><span className='data'>09-18-2020</span></div>
                    <div><span className="label">Last Sale Price</span><span className='data'>$1,000,000</span></div>
                  </div>
                </div>

                <div className="property">
                  <div className="thumbnail-image">
                    <img src="http://reonline.harriscomputer.com/getpicture.aspx?ID=954707" />
                  </div>
                  <div className="maplot">
                    <div><span className="label">Map/Lot</span><span className='data'>08-01-15</span></div>
                  </div>
                  <div className="name">
                    <h4>Katrina L Abel</h4>
                    <p>189 Hanscom Rd</p>
                  </div>
                  <div className="data-numbers">
                    <div><span className="label">Building + Land</span><span className='data'>53,090</span></div>
                    <div><span className="label">Acres</span><span className='data'>1.97</span></div>
                    <div><span className="label">Last Sale Date</span><span className='data'>09-18-2020</span></div>
                    <div><span className="label">Last Sale Price</span><span className='data'>$1,000,000</span></div>
                  </div>
                </div> */}

               

              </div>

            </div>
          </div>
        </div>
      </div>


    </div>
    <div className='page-bar'>
      <div className='page-controls'>
              <button className='btn' onClick={gotoFirstPage}>{'<<'}</button>
              <button className='btn' onClick={gotoPreviousPage}>{'<'}</button>
              <div className='pageNumbers'><h5>Page&nbsp;</h5> <div className='the-bar'><input className='input-bar2' type="number" value={currentPage}  onInput={e => setCurrentPage(e.target.value)} onKeyDown={handleKeyDown} /> </div> <h5 className='outOf'> of {totalPages}</h5></div>
              <button className='btn' onClick={gotoNextPage}>{'>'}</button>
              <button className='btn' onClick={gotoLastPage}>{'>>'}</button>
              </div>
              {/*<a className='btn feedback' to={`https://harrislocalgov.wufoo.com/forms/m1abqty80gtgey3/`}>Feedback</a>*/}
            </div>
    <AdvancedSearchModal isOpen={isAdvancedSearchModalOpen} setOpen={setAdvancedSearchModalOpen} filters={filters} setFilters={setFilters} search={search} client_id={clientId}/>
    <InfoModal isOpen={isInfoModalOpen} setOpen={setInfoModalOpen} location={`${params.city}, ${params.state}`} townInfo={townInfo}/>
    
    </>      
  );
}

export default Search;
