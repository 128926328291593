import { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { useParams } from 'react-router-dom';
import '../List.css';
import './ContactList.css';
import trashIcon from "../../icons/trash-icon.svg";
import editIcon from "../../icons/pencil-icon.svg";
import magnifyingGlass from "../../icons/magnifying-glass-solid.svg";
import ContactModal from '../ContactModal/ContactModal';
import toast from 'react-hot-toast';

function ContactList({client_id}) {
    const [searchQuery, setSearchQuery] = useState("");
    const [contacts, setContacts] = useState([]);
    const [filteredContacts, setFilteredContacts] = useState([]);
    const [contactModalOpen, setContactModalOpen] = useState(false);
    const [selectedContact, setSelectedContact] = useState(null);
    const [clientID, setClientID] = useState();

    let params = useParams();


    /**
     * Update contact list
     * @param {Array} newList New list of contacts 
     */
    const updateContactsList = (newList) => {
        setContacts(newList);
        search();
    }


    /**
     * On page load, fetch client id and contact data
     */
    useEffect(() => {
        //fetch contacts from backend
        const fetchData = async () => {
            let clientId = client_id;
            //if not provided client_id, get client_id from params
            if(!clientId) {
                const clientData = await fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/client/${params.state}/${params.city}`);
                const json = await clientData.json();
                clientId = json.ClientID;
                setClientID(clientId);
            }
            const contactData = await fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/contacts/${clientId}`, {
                credentials: "include"
            });
            const contactJson = await contactData.json();
            // console.log(contactJson);
            if(!contactJson.hasOwnProperty("error")) {
                setContacts(contactJson);
                setFilteredContacts(contactJson);
            }
             
        }
        fetchData().catch(e => console.log(e));
    }, []);

    /**
     * Filter contacts to ones that include the inputted text
     */
    const search = () => {
        setFilteredContacts(contacts.filter(a => a.Title.trim().toLowerCase().includes(searchQuery.trim().toLowerCase())));
    }
    const newContact = () => {
        setContactModalOpen(true);
    }

    const editContact = (contact_id) => {
        findContactByID(contact_id);
    }

    let accessToken = localStorage.getItem('accessToken');
    const deleteContact = (contact_id) => {
        fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/contact/${contact_id}`, {
            method: "DELETE",
            credentials: "include",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization' : accessToken
              },
          }).then(() => {
            toast.success("Contact deleted");
            setContacts((prev) => ( 
                prev.filter((c) => c.ContactID !== contact_id)
            ));
            
        });
    }
    const findContactByID = (contact_id) => {
        //find selected contact by contact_id
        for(let i = 0; i < contacts.length; i++) {
            if(contacts[i].ContactID == contact_id) {
                setSelectedContact(contacts[i]);
                break;
            }
        }
    }
    //update displayed contacts when full list updates
    useEffect(() => {
        // console.log(contacts);
        search();
    }, [contacts]);

    useEffect(() => {
        if(selectedContact) setContactModalOpen(true);
    }, [selectedContact]);
    useEffect(() => {
        if(!contactModalOpen) setSelectedContact(null);
    }, [contactModalOpen]);
    return (
        <>
        <div className="contact-list-module list-module">
            <div className='header'>
                <h3>Contacts</h3>
                <div className="input-bar">
                    <input type="text" value={searchQuery} onInput={e => setSearchQuery(e.target.value)} />
                    <button className="search-btn" onClick={() => search()} data-background-color="#8781a1" data-tip="Search" data-for="searchbtn"><img src={magnifyingGlass} className="magnifying-glass-icon"/></button>
                    <ReactTooltip id="searchbtn" place="top" type="info" effect="solid" />
                </div>
            </div>
            
            <div className="contact-list list">
                <table>
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Email</th>
                            <th style={{fontWeight: 400, justifyContent: "flex-end", display:'flex'}}>
                                <button className='btn add-btn' onClick={newContact} data-background-color="#8781a1" data-tip="Add Contact" data-for="addbtn">+</button>
                                <ReactTooltip id="addbtn" place="top" type="info" effect="solid" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {filteredContacts?.map((contact, index) => {
                        return(
                        <tr key={`contact${index}`}>
                            <td>{contact.Title}</td>
                            <td>{contact.ContactEmail}</td>
                            <td className='buttons'>
                                <button className='btn edit-btn' onClick={() => {editContact(contact.ContactID)}} data-background-color="#8781a1" data-tip="Edit" data-for="editbtn"><img src={editIcon}/></button>
                                <ReactTooltip id="editbtn" place="top" type="info" effect="solid" />
                                <button className='btn delete-btn' onClick={() => {deleteContact(contact.ContactID)}} data-background-color="#8781a1" data-tip="Delete" data-for="deletebtn"><img src={trashIcon}/></button>
                                <ReactTooltip id="deletebtn" place="top" type="info" effect="solid" />
                            </td>
                        </tr>
                        );
                    })}

                    </tbody>
                </table>
            </div>
        </div>
        <ContactModal isOpen={contactModalOpen} setOpen={setContactModalOpen} contact={selectedContact} clientID={clientID} setContacts={updateContactsList}/>
        </>
    );
}


export default ContactList;