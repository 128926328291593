import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Login from '../login/Login';
import './ClientAdmin.css';
import ClientAdminMain from './ClientAdminMain';
import { UserContext } from "../../hooks/useContext";
import toast from 'react-hot-toast';


function ClientAdmin() {
    const [user, setUser] = useContext(UserContext);
    const [clientID, setClientID] = useState();
    const params = useParams();
    const [isTokenValid,SetTokenisValid] = useState(false);
     
    /**
     * On page load, fetch client id
     */
    useEffect(() => {
        let accessToken = localStorage.getItem('accessToken');
         fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/client/${params.state}/${params.city}`, {
          method: "Get",
          credentials: 'include',
          'Authorization': accessToken
    
        })
        .then(res => res.json())
        .then(data => {
            if (!data?.ClientID)
            {
         
                window.location.replace('/');
                window.alert("Unable to find municipality");        
            }
            setClientID(data.ClientID);
            
            if (data?.TokenValid)
            {
              SetTokenisValid(true);
    
       
              
                
            }
            else
            {
              setUser(null);
              localStorage.setItem('UserId',null);
              localStorage.setItem('accessToken',null);
           
              SetTokenisValid(false);
            }
            
         
        });
       
     }, []);


    //if theres a session, return clientAdminMain
    //else render login form
    return (
      
        <>{user && (user?.Manages == null || user?.Manages == clientID)  /*&&  isTokenValid */?<ClientAdminMain/> : <Login  />}</>
    );
}


export default ClientAdmin;