import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import '../List.css';
import './SiteAdminList.css';
import trashIcon from "../../icons/trash-icon.svg";
import editIcon from "../../icons/pencil-icon.svg";
import magnifyingGlass from "../../icons/magnifying-glass-solid.svg";
import AdminModal from '../AdminModal/AdminModal';
import toast from 'react-hot-toast';
import { UserContext } from "../../hooks/useContext";


function SiteAdminList({client_id}) {
    const [user, setUser] = useContext(UserContext);
    const [searchQuery, setSearchQuery] = useState("");
    const [unauthorized, setUnauthorized] = useState(false);
    const [adminModalOpen, setAdminModalOpen] = useState(false);
    const [admins, setAdmins] = useState([]);
    const [filteredAdmins, setFilteredAdmins] = useState([]);
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [clientID, setClientID] = useState(client_id);

    let params = useParams();

    // useEffect(() => {
    //     console.log(clientID);
    // }, [clientID]);

    const updateAdminsList = (newList) => {
        setAdmins(newList);
        search();
    }

    /**
     * When the client_id is set, fetch client_id and admin data
     */
    
    useEffect(() => {
        //fetch admins from backend
        const fetchData = async () => {
            let clientId = client_id;
            //if not provided client_id, get client_id from params
            if(!clientId) {
                const clientData = await fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/client/${params.state}/${params.city}`, {
                    credentials: "include",
                    headers: {
                       'Content-Type': 'application/json'                        
                    }
                });
                const json = await clientData.json();
                clientId = await json.ClientID;
                // console.log(clientId);
            }
            setClientID(clientId);
            console.log(client_id, clientId, clientID);
            
            let accessToken = localStorage.getItem('accessToken');
            const adminData = await fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/users/${clientId}`, {
                credentials: "include",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization' : accessToken
                  },
            }, [client_id]);
            const adminJson = await adminData.json();
            console.log(adminJson);
            if(!adminJson?.error) {
                setAdmins(adminJson);
                setFilteredAdmins(adminJson);
            }
            else {
                //There is an error
                setUnauthorized(true);
            }
             
        }
        fetchData().catch(e => console.log(e));

    }, [client_id]);

    /**
     * Filter admins to ones that include the inputted text
     */
    const search = () => {
        if (Array.isArray(admins) )
        {
            
            setFilteredAdmins(admins.filter(a => a.Username.trim().toLowerCase().includes(searchQuery.trim().toLowerCase())));
        }
      
    }

    const newAdmin = () => {
        setAdminModalOpen(true);
    }

    const editAdmin = (admin_id) => {
        findAdminByID(admin_id);
    }

    let accessToken = localStorage.getItem('accessToken');
    const deleteAdmin = (admin_id) => {
        fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/user/${admin_id}`, {
            method: "DELETE",
            credentials: "include",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization' : accessToken
              },
          }).then(() => {
            toast.success("Admin deleted");
            setAdmins((prev) => ( 
                prev.filter((a) => a.UserId !== admin_id)
            ));
            
        });
    }
    const findAdminByID = (admin_id) => {
        //find selected admin by admin_id
        for(let i = 0; i < admins.length; i++) {
            if(admins[i].UserId == admin_id) {
                setSelectedAdmin(admins[i]);
                break;
            }
        }
    }

    //update displayed admins when full list updates
    useEffect(() => {
        search();
    }, [admins]);

    useEffect(() => {
        if(selectedAdmin) setAdminModalOpen(true);
    }, [selectedAdmin]);
    useEffect(() => {
        if(!adminModalOpen) setSelectedAdmin(null);
    }, [adminModalOpen]);

    if(unauthorized) return (<></>);
    return (
        <>
        <div className="admin-list-module list-module">
            <div className='header'>
                <h3>Admins</h3>
                <div className="input-bar">
                    <input type="text" value={searchQuery} onInput={e => setSearchQuery(e.target.value)} />
                    <button className="search-btn" onClick={() => search()} data-background-color="#8781a1" data-tip="Search" data-for="searchbtn"><img src={magnifyingGlass} className="magnifying-glass-icon"/></button>
                    <ReactTooltip id="searchbtn" place="top" type="info" effect="solid" />
                </div>
            </div>
            
            <div className="admin-list list">
                <table>
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>Super Admin</th>
                            <th>Site Admin</th>
                            <th style={{fontWeight: 400, justifyContent: "flex-end", display:'flex'}}>
                                <button className='btn add-btn' onClick={newAdmin} data-background-color="#8781a1" data-tip="Add Admin" data-for="addbtn">+</button>
                                <ReactTooltip id="addbtn" place="top" type="info" effect="solid" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {/* Map through admins and generate one per */}
                    {
                    
                   
                    Array.isArray(filteredAdmins)? filteredAdmins?.map((admin, index) => {
                        return (
                        <tr key={`admin${index}`} className={ admin.UserId == user.UserId? 'disabled' : 'none'}>
                            <td>{admin.Username}</td>
                            <td><input type="checkbox" disabled checked={admin.RoleID <= 2}/></td>
                            <td><input type="checkbox" disabled checked={admin.RoleID <= 3}/></td>
                            <td className='buttons'>
                                <button className='btn edit-btn' onClick={() => {editAdmin(admin.UserId)}} disabled={admin.UserId == user.UserId} data-background-color="#8781a1" data-tip="Edit" data-for="editbtn"><img src={editIcon}/></button>
                                <ReactTooltip id="editbtn" place="top" type="info" effect="solid" />
                                <button className='btn delete-btn' onClick={() => {deleteAdmin(admin.UserId)}} disabled={admin.UserId == user.UserId} data-background-color="#8781a1" data-tip="Delete" data-for="deletebtn"><img src={trashIcon}/></button>
                                <ReactTooltip id="deletebtn" place="top" type="info" effect="solid" />
                            </td>
                        </tr>
                        );
                    }) : <> </>}
                        
                    </tbody>
                </table>
            </div>
        </div>
        <AdminModal isOpen={adminModalOpen} setOpen={setAdminModalOpen} admin={selectedAdmin} clientID={clientID} setAdmins={updateAdminsList} />
        </>
    );
}


export default SiteAdminList;