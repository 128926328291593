import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserContext } from "../../hooks/useContext";
import Login from "../login/Login";
import HarrisAdminClientPage from "./HarrisAdminClientPage";

function HarrisAdmin() {
    const [user, setUser] = useContext(UserContext);
    const [clientID, setClientID] = useState();
    const params = useParams();

      // If the user is logged in and is a harris admin (RoleID = 1), redirect them to the admin page, otherwise show them the login page
    return (
        <>{user && (user?.RoleID == 1) ? <HarrisAdminClientPage /> : <Login harrisAdmin={true}/>}</>
    );
}

export default HarrisAdmin;