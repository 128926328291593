import './ClientConfigModal.css';
import '../Modal.css';
import SiteAdminList from '../../partials/SiteAdminList/SiteAdminList';
import toast from 'react-hot-toast';
import trashIcon from "../../icons/trash-icon.svg";
import { Transition } from "react-transition-group";
import { useEffect, useState } from 'react'

function ClientConfigModal({ isOpen, setOpen, selectedClient, updateClientList }) {
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [selectedState, setSelectedState] = useState(selectedClient ? selectedClient.StateProvince : "");
    const [selectedCountry, setSelectedCountry] = useState(selectedClient ? selectedClient.Country : "");
    const [selectedAllows, setSelectedAllows] = useState(selectedClient ? selectedClient.AllowsImage : false);
    const [selectedActive, setSelectedActive] = useState(selectedClient ? selectedClient.IsActive : false);
    const [selectedProduction, setSelectedProduction] = useState(selectedClient ? selectedClient.IsProduction : false);
    const [selectedPay, setSelectedPay] = useState(selectedClient ? selectedClient.AllowPaymentLink : false);
    const [isStateSelectionLocked, setStateSelectionLocked] = useState(true);
    const [selectedPicture, setSelectedPicture] = useState(selectedClient ? selectedClient.PictureLevel : "");
    const [PayLink, setPayLink] = useState(selectedClient ? selectedClient.PaymentLink : "");
    const [stateList, setStateList] = useState([]);
    const [countryList, setCountryList] = useState([]);
    const closeModal = () => { setOpen(false) };
    const [value, setValueGov] = useState('');


    //Production/test environment
    const handleChangeProductionRadio = (event) => {
        if (event.target.value === 'Production') {
            setSelectedProduction(true);

        } else {
            setSelectedProduction(false);
        }
    };

    //picture link handlers
    const handleChangePictureRadio = (event) => {
        if (event.target.value === 'AllowImage') {
            setSelectedAllows(true);

        } else {
            setSelectedAllows(false);
        }
    };

    const toggleTxtPicturelink = (event) => {

        const pictureTextBox = document.getElementById('ImageLinkTextbox');
        if (event.target.value === 'AllowImage') {
            pictureTextBox.disabled = false;

        } else {
            setSelectedPicture("");
            pictureTextBox.disabled = true;
        }

    }


    //Payment Link Handlers

    const handleChangePaymentLinkRadio = (event) => {
        if (event.target.value === 'AllowPayment') {
            setSelectedPay(true);

        } else {
            setSelectedPay(false);
        }
    };

    const toggleTxtPaylink = (event) => {
        const payTextBox = document.getElementById('PayLinkTextbox');

        if (event.target.value === 'AllowPayment') {
            payTextBox.disabled = false;


        } else {
            setPayLink("");
            payTextBox.disabled = true;

        }

    };








    /**
     * Update client information (state, country, isActive)
     */
    const updateClientInfo = () => {
        let fetchBody = {
            City: name,
            StateProvince: selectedState,
            Country: selectedCountry,
            Description: description,
            IsActive: selectedActive,
            AllowsImage: selectedAllows,
            IsProduction: selectedProduction,
            AllowPaymentLink: selectedPay,
            PaymentLink: PayLink,
            PictureLevel: selectedPicture

        }
        let accessToken = localStorage.getItem('accessToken');
        fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/client/${selectedClient.ClientID}`, {
            method: "PATCH",
            mode: 'cors',
            credentials: "include",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': accessToken
            },
            body: JSON.stringify(fetchBody)
        },[accessToken])//.then(res => res.json() )
        .then(res => {
            if (res.status == 401) {
                //show error
                console.log(value);
                toast.error("Session Expired");
               
                localStorage.setItem('UserId', null);
                localStorage.setItem('accessToken', null);
                //setError(true);
            }
            else {
               // res => res.json();
                //successfully updated client
                toast.success("Client saved");
                updateClientList((prev) => {
                    return updateClientsState(prev, res.json());
                })
                closeModal();
            }
        },[accessToken]);
    }

    let accessToken = localStorage.getItem('accessToken');
    const deleteClient = () => {
        fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/client/${selectedClient.ClientID}`, {
            method: "DELETE",
            credentials: 'include',
            'Authorization': accessToken

        }, [accessToken]).then(() => {
            toast.success("Client deleted");
            let loc = window.location;
            window.location.replace(loc);
        })
        closeModal();
    }

    /**
     * Update client in list of clients
     * @param {Array} prev An array of client objects 
     * @param {Object} data New client data
     * @returns Updated list of clients
     */
    const updateClientsState = (prev, data) => {
        let temp = prev;
        for (let i = 0; i < temp.length; i++) {
            if (temp[i].ClientID == data.ClientID) {
                temp[i] = data;
                break;
            }
        }
        return temp;
    }




    /**
     * When selectedClient is set, use that data. Otherwise, use default values
     */
    useEffect(() => {
        setName(selectedClient ? selectedClient.City : "");
        setSelectedState(selectedClient ? selectedClient.StateProvince : "");
        setSelectedCountry(selectedClient ? selectedClient.Country : "");
        setDescription(selectedClient ? selectedClient.Description : "");
        setSelectedActive(selectedClient ? selectedClient.IsActive : false);
        setSelectedAllows(selectedClient ? selectedClient.AllowsImage : false);
        setSelectedProduction(selectedClient ? selectedClient.IsProduction : false);
        setSelectedPay(selectedClient ? selectedClient.AllowPaymentLink : false);
        setPayLink(selectedClient ? selectedClient.PaymentLink : "");
        setSelectedPicture(selectedClient ? selectedClient.PictureLevel : "");
    }, [selectedClient])

    // if(isOpen)
    return (
        <Transition in={isOpen} timeout={{ enter: 0, exit: 150 }} appear>
            {state => (
                <div className={`client-config-modal modal-container ${state}`}>
                    <div className="screen" onClick={closeModal}></div>
                    <div className="modal">
                        <button className="close-modal-btn" onClick={closeModal}></button>
                        <h1>Settings for: {selectedClient.City}</h1>
                        <div className='location-section modal-section'>
                            <div className='input-group'>
                                <label htmlFor='name-input'>Client Name</label>
                                <input id='name-input' type="name" autoComplete='off' value={name} onChange={(event) => setName(event.target.value)} />
                            </div>
                            <div className='input-group'>
                                <label htmlFor='county-select'>Country</label>
                                <input id='country-select' list='country-list' autoComplete='off' value={selectedCountry} onChange={(event) => setSelectedCountry(event.target.value)} />
                                <datalist id='country-list'>
                                    <option value="" />
                                    {
                                        countryList?.map((input, index) => {
                                            return (<option key={`country${index}`} value={input} />);
                                        })
                                    }
                                </datalist>
                            </div>
                            <div className='input-group'>
                                <label htmlFor='state-select'>State/Province</label>
                                <input id="state-select" list="state-list" autoCorrect="off" value={selectedState} onChange={(event) => setSelectedState(event.target.value)} disabled={isStateSelectionLocked} />
                                <datalist id="state-list">
                                    <option value="" />
                                    {
                                        stateList.map((input, index) => {
                                            return (<option key={`state${index}`} value={input} />);
                                        })
                                    }
                                </datalist>
                            </div>
                            <div className="input-group">
                                <label htmlFor="description-input">Description</label>
                                <input type="name" id='description-input' autoComplete='off' value={description} onChange={(event) => setDescription(event.target.value)} />
                            </div>

                        </div>
                        <div className='status-picture-section modal-section'>
                            <div className='input-group'>
                                <div className='status-label'>
                                    <label htmlFor='status-selection'>Status</label>
                                </div>
                                <div className='radio-selection'>
                                    <label htmlFor='active-status'>Active</label>
                                    <input type='radio' id='active-status' name='active-status' value='true' checked={selectedActive} onChange={(event) => setSelectedActive(true)} />
                                </div>
                                <div className='radio-selection'>
                                    <label htmlFor='inactive-status'>Inactive</label>
                                    <input type='radio' id='inactve-status' name='inactive-status' value='false' checked={!selectedActive} onChange={(event) => setSelectedActive(false)} />
                                </div>
                            </div>
                        </div>

                        <div className='status-picture-section modal-section'>
                            <div className='input-group'>
                                <div className='production-label'>
                                    <label htmlFor='production-selection'>Type</label>
                                </div>
                                <div className='radio-selection'>
                                    <label htmlFor='production-status'>Production</label>
                                    <input type='radio' id='production-status'
                                        name='selectProdStatus'
                                        value='Production'
                                        checked={selectedProduction}
                                        onChange={(event) => {
                                            handleChangeProductionRadio(event);
                                        }} />
                                </div>
                                <div className='radio-selection'>
                                    <label htmlFor='test-status'>Test</label>
                                    <input type='radio' id='selectTestStatus'
                                        name='selectTestStatus' value='Test'
                                        checked={!selectedProduction}
                                        onChange={(event) => {
                                            handleChangeProductionRadio(event);
                                        }} />
                                </div>
                            </div>
                        </div>

                        {/* Allow Image Selection */}
                        <div className="status-img-selection modal-section">
                            <div className="input-group">
                                <div className="status-img-label">
                                    <label htmlFor="status-img-selection">Allows Image</label>
                                </div>
                                <div className="radio-selection">
                                    <label htmlFor="allow-status">Allows</label>
                                    <input type='radio' id='allow-imgstatus'
                                        name='imgStatusOption'
                                        value='AllowImage'
                                        checked={selectedAllows}
                                        onChange={(event) => {
                                            handleChangePictureRadio(event);
                                            toggleTxtPicturelink(event);
                                        }}
                                    />
                                </div>
                                <div className="radio-selection">
                                    <label htmlFor="disallow-status">Deny</label>
                                    <input type='radio' id='deny-gwlstatus'
                                        name='imgStatusOption'
                                        value='deny-Imgstatus'
                                        checked={!selectedAllows}
                                        onChange={(event) => {
                                            handleChangePictureRadio(event);
                                            toggleTxtPicturelink(event);
                                        }}
                                    />
                                </div>

                            </div>

                            <div className="input-group">
                                <label htmlFor="picture-selection">Picture Level (Per Property)</label>
                                <input type="name" id='ImageLinkTextbox' autoComplete="off" value={selectedPicture} onChange={(event) => setSelectedPicture(event.target.value)} disabled={selectedClient ? !selectedClient.AllowsImage : true} />
                            </div>

                        </div>

                    
                        {/* Payment Link section */}
                        <div className="status-pay-selection modal-section">
                            <div className="input-group">
                                <div className="status-pay-label">
                                    <label htmlFor="status-pay-selection">Allows Payment link</label>
                                </div>
                                <div className="radio-selection">
                                    <label htmlFor="AllowPaymentLinkOption">Allows</label>
                                    <input
                                        type="radio"
                                        id="AllowPaymentLinkOption"
                                        name="PaymentLinkOption"
                                        value="AllowPayment"
                                        checked={selectedPay}
                                        onChange={(event) => {
                                            handleChangePaymentLinkRadio(event);
                                            toggleTxtPaylink(event);
                                        }}
                                    />
                                </div>
                                <div className="radio-selection">
                                    <label htmlFor="status-pay-selection">Deny</label>
                                    <input
                                        type="radio"
                                        id="DenyPaymentLinkOption"
                                        name="PaymentLinkOption"
                                        value="DenyPaymentLinkOption"
                                        checked={!selectedPay}
                                        onChange={(event) => {
                                            handleChangePaymentLinkRadio(event);
                                            toggleTxtPaylink(event);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="input-group">
                                <label htmlFor="link-selection">Payment Link</label>
                                <input type="name" id="PayLinkTextbox" autoComplete="off" value={PayLink}
                                    onChange={(event) => setPayLink(event.target.value)}
                                    disabled={selectedClient ? !selectedClient.AllowPaymentLink : true}
                                />
                            </div>

                        </div>


                        <SiteAdminList client_id={selectedClient.ClientID} />
                        <div className='update-btn'>
                            <button className='btn primary' onClick={updateClientInfo}>Update</button>
                        </div>
                        <div className='delete-btn'>
                            <button className='btn delete-btn' onClick={deleteClient}><img src={trashIcon} /></button>
                        </div>

                    </div>
                </div>
            )}

        </Transition>
    );
    // else return <></>;
}

export default ClientConfigModal;