import React, { Component } from "react";
import "./error-boundary.css";

/**
 * React Error boundary to catch UI erorrs during runtime. (prevents the app from completly crashing)
 */
export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  /**
   * Sets the state of the component to an error
   * @param {Object} _error
   */
  static getDerivedStateFromError(_error) {
    return { hasError: true };
  }

  /**
   * Gets detailed information on the error
   * @param {Error} error
   * @param {Object} errorInfo
   */
  componentDidCatch(error, errorInfo) {
    console.log(JSON.stringify(error));
    this.setState({ error, errorInfo });
  }

  /**
   * Converts error text to URL rich React nodes
   * @param {String} string
   * @returns {React.ReactNode}
   */
  URLify(string) {
    if (!string) return "No Trace";
    string = string.trim();
    var urls = string.match(/(((ftp|https?):\/\/)[\-\w@:%_\+.~#?,&\/\/=]+)/g);
    var files = string.split("\n"),
      container = <></>,
      i = 0;
    if (urls)
      container = React.createElement(
        "div",
        {},
        urls.map((url) => {
          const text = files[i].replace(url, "");
          i++;
          return React.createElement(
            "span",
            {},
            "in ",
            React.createElement("b", {}, text.replace("@", "")),
            ` @ ${url.replace(window.location.origin, "")}`,
            React.createElement("br")
          );
        })
      );
    return container;
  }

  render() {
    const { error, errorInfo, hasError } = this.state;
    const toHome = () => (window.location.href = "/");

    const styles = {
      container: {
        padding: 25,
      },
      err_desc: {
        padding: "5px 8px",
        background: "rgb(253, 236, 235)",
        borderRadius: 3,
        color: "rgb(212, 60, 48)",
      },
    };

    if (hasError)
      return (
        <div style={styles.container}>
          <h1>Something went wrong.&nbsp;&nbsp;:&#40;</h1>
          <div>
            🐜 A bug found its way into the website! We will work to exterminate
            it and remove points of entry.
            <br />
            <button className="btn primary" onClick={toHome}>
              Return Home
            </button>
            <br />
            <i>
              For our curious customers, feel free to explore the bug's trail of
              destruction:
            </i>
          </div>
          <p>
            <b>Error:</b>{" "}
            <pre style={styles.err_desc}>
              {error ? error.message ?? "No Error Message" : "Unknown Error"}
            </pre>
            <i>Open browser DevTools for more details and actions.</i>
          </p>
          <p style={{ whiteSpace: "pre-wrap" }}>
            <b>Trace:</b>&#10;&#13;
            <pre style={styles.err_desc}>
              {errorInfo ? this.URLify(errorInfo.componentStack) : "No Trace"}
            </pre>
          </p>
        </div>
      );
    return this.props.children;
  }
}
