import { createRef, useEffect, useState, Fragment, useRef, useCallback, dangerouslySetInnerHTML, React } from 'react';
import { useParams, Link, useNavigate } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import './Listing.css';
import ImageViewer from 'react-simple-image-viewer';
import gisIcon from "../../icons/gis-icon.svg";
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import backArrowIcon from "../../icons/back-arrow-icon.svg";
import { useStyleMediaQuery } from '../../hooks/useStyleMediaQuery';
import ReactToPdf from 'react-to-pdf';
import {formatDate} from '../../utils/DateFormat';
 
function validateURL(url) {


  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(url);
}
function Listing() {



  const { matches: isLarge } = useStyleMediaQuery({ mixOrMax: 'min', widthOrHeight: 'width', value: 951 });
  const { matches: isMedium } = useStyleMediaQuery({ minOrMax: 'max', widthOrHeight: 'width', value: 950 });
  const { matches: isMobile } = useStyleMediaQuery({ minOrMax: 'max', widthOrHeight: 'width', value: 767 });
  const { matches: isSmall } = useStyleMediaQuery({ minOrMax: 'max', widthOrHeight: 'width', value: 600 });

  
  const ListingRef = useRef();
  let params = useParams();
  let navigate = useNavigate();
  const [property, setProperty] = useState({});


  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [showExempt, setShowExempt] = useState(false);

  const [showResultNav, setShowResultNav] = useState(true);

  const [townIDImage, setTownIDImage] = useState();
  const [baseImageURL, setBaseImageURL] = useState(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/files/image/`);

  const dash = "-";

  const [currentPosition, setCurrentPosition] = useState(1);
  const [results, setResults] = useState(null);
  const [pageURL, setPageURL] = useState(`${params.state}/${params.city}/${params.account}`);


  const [showButton, setShowButton] = useState();
  const [paymentUrl, setPaymentUrl] = useState();

  const [isValid, setIsValid] = useState(false);
  const openURL = () => { window.open(paymentUrl, '_blank', 'noreferrer') };
  if (openURL) {
    openURL.opener = null;
  }

  /**
   * When the pageURL is set, fetch and store property data
   */
  useEffect(() => {
    const fetchData = async () => {
      //get banner image
      let clientId = await fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/client/${params.state}/${params.city}`)
      clientId = (await clientId.json()).ClientID;
      console.log(clientId);
      let clientInfo = await fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/client/${clientId}`)
      clientInfo = await clientInfo.json()
      if (clientInfo.status === 500) {
        window.location.replace('/')
        window.alert("Unable to find property, invalid Client ID.")
      }

      if (clientInfo.BannerImage) setTownIDImage(`${baseImageURL}${clientInfo.BannerImage}`);

    

      let prop = await fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/fullproperty/${params.account}?city=${params.city}&state=${params.state}`)
      //let prop = await fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/property/${params.propertyId}`)
      prop = await prop.json();
      if (prop.status === 500 || prop.status === 404) {
        window.location.replace('/')
        window.alert("Unable to find property, invalid property.")
      }
      prop.EnableImages = true;
      prop.EnableGWL = true;

      setProperty(prop);


      if (clientInfo.AllowPaymentLink && prop.TotalTaxOutstanding > 0) {
        setShowButton(clientInfo.AllowPaymentLink);

        //Adding http protocol in case is not added
        if (!clientInfo.PaymentLink.toLowerCase().includes("https://", 0)
          && !clientInfo.PaymentLink.toLowerCase().includes("http://", 0)) {
          clientInfo.PaymentLink = "https://" + clientInfo.PaymentLink ;
        }

        setPaymentUrl(clientInfo.PaymentLink+prop.Account);

      }

    }
    fetchData().catch(e => console.log(e));
  }, [pageURL])

 
  const buildingSectionIsEmpty = (prop) => {
    if (!prop) {
      return true;
    }
    if ((!prop?.Buildings || prop?.Buildings?.length < 1) && (!prop?.CommercialBuildings || prop?.CommercialBuildings?.length < 1)) {
      return true;
    }
    return false;
  };


  const collectionIsEmpty = (collection) => {
    if (!collection || collection?.length < 1) {
      return true;
    }
    return false;
  }

  /**
   * Open the image viewer with a specified image showing first
   * @param {Number} index Index of image to view
   */
  const openImageViewer = (index) => {
    setCurrentImage(index);
    document.body.style.overflow = 'hidden';
    setIsViewerOpen(true);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  /**
   * Close the image viewer
   */
  const closeImageViewer = () => {
    setCurrentImage(0);
    document.body.style.overflow = '';
    setIsViewerOpen(false);
  }

  /**
   * Sum up the values of the exempts
   * @param {Array} exempts Array of exempts
   * @returns sum of exempt's values
   */
  const exemptSum = (exempts) => {
    return exempts.reduce((prev, e) => prev.value + e.value);
  }

  /**
   * Get position of result in result list if there is a list of results
   * @param {Array} results Search results
   * @returns Index of current result or null if not found
   */
  const getCurrentPosition = (results) => {
    if (!results) return null;
    for (let i = 0; i < results.length; i++) {
      if (results[i] === pageURL) {
        return (i + 1);
      }
    }
    return null;
  }

  /**
   * When the pageURL is set, get data out of localstorage
   */
  useEffect(() => {
    //On load, check to see if data is in localstorage
    let storage = JSON.parse(localStorage.getItem(`${params.state}-${params.city}-results`));
    if (storage) {
      //Within ttl
      if (storage.ttl && storage.ttl >= Date.now()) {
        setResults(storage.results);
        // setCurrentPosition(storage.position);
        let pos = getCurrentPosition(storage.results);
        if (pos) setCurrentPosition(pos);
        else setShowResultNav(false);
      }
      //ttl is expired: delete data
      else {
        localStorage.removeItem(`${params.state}-${params.city}-results`);
        setShowResultNav(false);
      }
    }
  }, [pageURL]);


  /**
   * Navigate to previous result in result list
   * @returns navigation to previous result page
   */
  const goToPrevResult = () => {
    if (currentPosition > 1) {
      setPageURL(results[currentPosition - 2]);
      return navigate(`/${results[currentPosition - 2]}`);
    }
    // return navigate();
  }

  /**
   * Navigate to next result in result list
   * @returns navigation to next result page
   */
  const goToNextResult = () => {
    if (currentPosition < results.length) {
      setPageURL(results[currentPosition]);
      return navigate(`/${results[currentPosition]}`);
    }
  }

  const makeBold = (textToBold) => {

  };
 

  const Print = () => {
    //console.log('print');  
    let printContents = document.getElementById('printablediv').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  }

  return (
    <Fragment>
      <div className="listing-page" ref={ListingRef}>
        <div className="header-section">

          <div>{townIDImage ? <img className='banner-image' src={townIDImage} /> : <h2>{params.city}, {params.state}</h2>}</div>
        </div>
        {property &&
          <div className="data-section">
            <div className='header'>
              <h1>{property?.StreetNumber && `${property?.StreetNumber}`}{!property?.Apartment?.includes('-') ? <> {property?.Apartment}</> : property?.Apartment} {property?.StreetName && `${property?.StreetName}`}{property?.GISLink ? <Link to={`${property?.GISLink ? property?.GISLink : "/"}`}><img className="gis-icon" src={gisIcon} /></Link> : <></>}</h1>
              <div className='owner-row'>

                {property?.Owners?.length > 0 ?
                  <div className='owner'>
                    <h2>{property?.Owners[0].OwnerName}</h2>
                    {property?.Owners.length > 1 ? <h2>{property?.Owners[1].OwnerName}</h2> : <></>}
                    <hr />
                    <p>{property?.Owners[0].Address1}</p>
                    <p>{property?.Owners[0].Address2}</p>
                    <p>{property?.Owners[0].Address3}</p>
                    <p>{property?.Owners[0].City}, {property?.Owners[0].State} {property?.Owners[0].ZipCode}</p>
                    <p>{property?.Owners[0].Country}</p>
                  </div> : <></>}
                {property?.EnableImages ? <Carousel showArrows={true} autoPlay={false} showStatus={false} showThumbs={false} className="image-carousel">
                  {property?.Images?.map((image, index) => (
                    <div className="image-slide-slide" key={image} onClick={() => openImageViewer(index)}>
                      <img className='the-image' src={`${process.env.REACT_APP_BACKEND_ORIGIN}${image}`} />
                    </div>
                  ))}
                </Carousel>
                  : <></>}

                <div className='page-data'>

                  <p>Map/Lot: {property?.MapLot}</p>
                  {property?.Book && <p>Book / Page: {property?.Book}-{property?.Page}</p>}
                  <p>Account: {property?.Account}</p>
                 
                  <p>Last Updated: {formatDate(property?.DateUpdated)}</p>
                </div>
              </div>
            </div>
            <div>
              <div className='fieldset-row'>
                <fieldset className='assessment'>
                  <legend>Assessment</legend>
                  <table className='table v-table'>
                    <tbody>
                      <tr>
                        <th>Land</th>

                        <td>${property?.LandValue?.toLocaleString(undefined, { maximumFractionDigits: 2 }) || 0}</td>
                      </tr>
                      <tr>
                        <th>Building</th>
                        <td>${property?.TotalBuildingValue?.toLocaleString(undefined, { maximumFractionDigits: 2 }) || 0}</td>
                      </tr>
                      <tr className='exempt-dropdown' onClick={() => setShowExempt(!showExempt)}>
                        <th>Exempt {property?.Exemptions && property?.Exemptions[0]?.ExemptionID != null ? <span className={showExempt ? "flipped arrow" : "arrow"}>&#9660;</span> : <></>}</th>
                        <td>${property?.TotalExemption?.toLocaleString(undefined, { maximumFractionDigits: 2 }) || 0}</td>
                      </tr>
                      <tr className='exempt-data'>
                        <td colSpan="2">
                          <div className={`v-table ${showExempt && property?.Exemptions && property?.Exemptions[0]?.ExemptionID != null ? "" : "hide"}`}>
                            <table className='v-table'>
                              <tbody>
                                {property?.Exemptions?.map((e) => {
                                  return (<tr>
                                    <th>{e.Description}</th>
                                    <td>${e.Value.toLocaleString(undefined, { maximumFractionDigits: 2 }) || 0}</td>
                                  </tr>);
                                })}
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                      {/* <tr>
                        <th>Exempt</th>
                        <td>${property?.TotalExemption}</td>
                      </tr> */}
                      <tr>
                        <th>Taxable</th>
                        <td>${property?.Taxable?.toLocaleString(undefined, { maximumFractionDigits: 2 }) || 0}</td>
                      </tr>
                    </tbody>
                  </table>
                </fieldset>
                <fieldset className={"sale-data" + " " + (collectionIsEmpty(property?.Sales) ? "empty-section" : "")}>
                  <legend>Sales</legend>
                  <div className="no-data-overlay">No Data</div>
                  {!(isMobile || isSmall) &&
                    <table className='table right-align h-table'>

                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Price</th>
                          <th>Book & Page</th>
                          <th>Selling Party</th>
                          <th>Sale Type</th>
                        </tr>
                      </thead>
                      <tbody>
                        {property?.Sales?.map((data, index) => {

                          return (<tr key={`saleData${index}`}>
                            <td>{data?.SaleDate && (formatDate(data?.SaleDate))}</td>
                            <td>{(data?.SalePrice != 0 && data?.SalePrice) && "$" + data?.SalePrice?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                            <td>{data?.Book && <b>Book: </b>}{data?.Book && + data?.Book}{data?.Page && <b> Page: </b>}{data?.Page && data?.Page}</td>
                            <td>{data?.SaleParty}</td>
                            <td>{data?.SaleType}</td>
                          </tr>);

                        })}
                      </tbody>
                    </table>
                  }
                  {(isMobile || isSmall) &&
                    property?.Sales?.map((data, i) => (
                      <Fragment key={`sale${i}`}>
                        <table className='table v-table'>
                          <tbody>
                            {data?.SaleDate &&
                              <tr>
                                <th>Date</th>
                                <td>{formatDate(data?.SaleDate)}</td>
                              </tr>
                            }
                            {data?.SalePrice &&
                              <tr>
                                <th>Price</th>
                                <td>${data?.SalePrice?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                              </tr>
                            }
                            {data?.Book &&
                              <tr>
                                <th>Book</th>
                                <td>{data?.Book}</td>
                              </tr>
                            }
                            {data?.Page &&
                              <tr>
                                <th>Page</th>
                                <td>{data?.Page}</td>
                              </tr>
                            }
                            {data?.SaleParty &&
                              <tr>
                                <th>Selling Party</th>
                                <td>{data?.SaleParty}</td>
                              </tr>
                            }
                            {data?.SaleType &&
                              <tr>
                                <th>Sale Type</th>
                                <td>{data?.SaleType}</td>
                              </tr>
                            }
                          </tbody>
                        </table>
                      </Fragment>
                    ))}
                </fieldset>
              </div>

              <div className='fieldset-row'>
                <div className='fieldset-col'>
                  <fieldset className='property-info'>
                    <legend>Property</legend>
                    <table className='table v-table'>
                      <tbody>
                        {property?.LandCode &&
                          <tr>
                            <th>Land Code</th>
                            <td>{property?.LandCode}</td>
                          </tr>}
                        {property?.BuildingCode &&
                          <tr>
                            <th>Building Code</th>
                            <td>{property?.BuildingCode}</td>
                          </tr>
                        }
                        {property?.UserDefinedValues &&
                          property?.UserDefinedValues['PropertyCode'] &&
                          <tr>
                            <th>{property?.UserDefinedValues['PropertyCode']?.Description}</th>
                            <td>{property?.UserDefinedValues['PropertyCode']?.UserDefinedValue}</td>
                          </tr>
                        }
                        {(property?.TotalAcres != 0 && property.TotalAcres) &&
                          <tr>
                            <th>Total Acreage</th>
                            <td>{property?.TotalAcres}</td>
                          </tr>}
                        {property?.UserDefinedValues && property?.UserDefinedValues['TaxLimit'] && property?.UserDefinedValues['TaxLimit']?.UserDefinedValue != "" &&
                          <tr>
                            <th>{property?.UserDefinedValues['TaxLimit']?.Description}</th>
                            <td>${(property?.UserDefinedValues['TaxLimit']?.UserDefinedValue || 0).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                          </tr>}
                        {property?.Neighborhood &&
                          <tr>
                            <th>Neighborhood</th>
                            <td>{property?.Neighborhood}</td>
                          </tr>}
                        {property?.Zone &&
                          <tr>
                            <th>Zone</th>
                            <td>{property?.Zone}</td>
                          </tr>}
                        {property?.SecondaryZone &&
                          <tr>
                            <th>Secondary Zone</th>
                            <td>{property?.SecondaryZone}</td>
                          </tr>}
                        {property?.UserDefinedValues && property?.UserDefinedValues['TreeGrowthYear'] && property?.UserDefinedValues['TreeGrowthYear']?.UserDefinedValue != "" &&
                          <tr>
                            <th>{property?.UserDefinedValues['TreeGrowthYear']?.Description}</th>
                            <td>{property?.UserDefinedValues['TreeGrowthYear']?.UserDefinedValue}</td>
                          </tr>}
                        {property?.UserDefinedValues &&
                          property?.UserDefinedValues['XCoord'] && property?.UserDefinedValues['XCoord']?.UserDefinedValue != "0" &&
                          <tr>
                            <th>{property?.UserDefinedValues['XCoord']?.Description}</th>
                            <td>{property?.UserDefinedValues['XCoord']?.UserDefinedValue}</td>
                          </tr>
                        }
                        {property?.UserDefinedValues &&
                          property?.UserDefinedValues['YCoord'] && property?.UserDefinedValues['YCoord']?.UserDefinedValue != "0" &&
                          <tr>
                            <th>{property?.UserDefinedValues['YCoord']?.Description}</th>
                            <td>{property?.UserDefinedValues['YCoord']?.UserDefinedValue}</td>
                          </tr>
                        }
                        {property?.TopographyDescription1 &&
                          <tr>
                            <th>Topography 1</th>
                            <td>{property?.TopographyDescription1}</td>
                          </tr>}
                        {property?.TopographyDescription2 &&
                          <tr>
                            <th>Topography 2</th>
                            <td>{property?.TopographyDescription2}</td>
                          </tr>}
                        {property?.UtilitiesDescription1 &&
                          <tr>
                            <th>Utilities 1</th>
                            <td>{property?.UtilitiesDescription1}</td>
                          </tr>}
                        {property?.UtilitiesDescription2 &&
                          <tr>
                            <th>Utilities 2</th>
                            <td>{property?.UtilitiesDescription2}</td>
                          </tr>}
                        {property?.StreetType &&
                          <tr>
                            <th>Street</th>
                            <td>{property?.StreetType}</td>
                          </tr>}
                        {property?.UserDefinedValues &&
                          property?.UserDefinedValues['Open1'] &&
                          <tr>
                            <th>{property?.UserDefinedValues['Open1']?.Description}</th>
                            <td>{property?.UserDefinedValues['Open1']?.UserDefinedValue}</td>
                          </tr>
                        }
                        {property?.UserDefinedValues &&
                          property?.UserDefinedValues['Open2'] &&
                          <tr>
                            <th>{property?.UserDefinedValues['Open2']?.Description}</th>
                            <td>{property?.UserDefinedValues['Open2']?.UserDefinedValue}</td>
                          </tr>
                        }
                        {property?.UserDefinedValues && property?.UserDefinedValues['Reference1'] && property?.UserDefinedValues['Reference1']?.UserDefinedValue != "" &&
                          <tr>
                            <th>{property?.UserDefinedValues['Reference1']?.Description}</th>
                            <td>{property?.UserDefinedValues['Reference1']?.UserDefinedValue}</td>
                          </tr>}
                        {property?.UserDefinedValues && property?.UserDefinedValues['Reference2'] && property?.UserDefinedValues['Reference2']?.UserDefinedValue != "" &&
                          <tr>
                            <th>{property?.UserDefinedValues['Reference2']?.Description}</th>
                            <td>{property?.UserDefinedValues['Reference2']?.UserDefinedValue}</td>
                          </tr>}
                        {property?.UserDefinedValues && property?.UserDefinedValues['TranCode'] && property?.UserDefinedValues['TranCode']?.UserDefinedValue != "" &&
                          <tr>
                            <th>{property?.UserDefinedValues['TranCode']?.Description}</th>
                            <td>{property?.UserDefinedValues['TranCode']?.UserDefinedValue}</td>
                          </tr>}
                      </tbody>
                    </table>
                  </fieldset>
                  {((property?.Land && property?.Land?.length > 0 && (isMobile || isSmall)) || !(isMobile || isSmall)) &&
                    <fieldset id="land-section" className={"land" + " " + (collectionIsEmpty(property?.Land) ? "empty-section" : "")}>
                      <legend>Land</legend>
                      <div className="no-data-overlay">No Data</div>
                      {property?.Land?.map((land, i) => (
                        <Fragment key={`land${i}`}>
                          <table className='table v-table'>
                            <tbody>
                              <tr>
                                <th>Code</th>
                                <td>{land?.LandCode}</td>
                              </tr>
                              {land?.LandDescription && land?.LandDescription != "" &&
                                <tr>
                                  <th>Units</th>
                                  <td>{land?.LandDescription}</td>
                                </tr>
                              }
                              {land?.UnitType && land?.UnitType != "" &&
                                <tr>
                                  <th>Type</th>
                                  <td>{land?.UnitType}</td>
                                </tr>
                              }
                              {land?.InfluenceDescription && land?.InfluenceDescription != "" &&
                                <tr>
                                  <th>Influence Description</th>
                                  <td>{land?.InfluenceDescription}</td>
                                </tr>
                              }
                              {land?.InfluencePercentage &&
                                <tr>
                                  <th>Influence</th>
                                  <td>{land?.InfluencePercentage}</td>
                                </tr>
                              }
                              {(land?.Amount != 0 && land?.Amount) &&
                                <tr>
                                  <th>Value</th>
                                  <td>${`${land?.Amount?.toLocaleString()}`}</td>
                                </tr>}
                            </tbody>
                          </table>
                          {/* <hr /> */}
                        </Fragment>
                      ))}
                    </fieldset>
                  }
                </div>

                <fieldset className={"building" + " " + (buildingSectionIsEmpty(property) ? "empty-section" : "")}>
                  <legend>Building</legend>
                  <div className="no-data-overlay">No Data</div>
                  {property?.Buildings?.map((building, i) => (
                    <table className='table v-table' key={`building${i}`}>
                      <thead>Residence
                      </thead>
                      <tbody>
                        {building?.BuildingType &&
                          <tr>
                            <th>Type</th>
                            <td>{building.BuildingType}</td>
                          </tr>
                        }
                        {!building.Area == 0 &&
                          <tr>
                            <th>Area</th>
                            <td>{building.Area} sqft{/*Has Two Values*/}</td>
                          </tr>
                        }
                        {building?.Attic &&
                          <tr>
                            <th>Attic</th>
                            <td>{building.Attic}</td>
                          </tr>}
                        {!building.Basement == '' &&
                          <tr>
                            <th>Basement</th>
                            <td>{building.Basement}</td>
                          </tr>
                        }
                        {building?.BasementGarage &&
                          <tr>
                            <th>Basement Garage</th>
                            <td>{building.BasementGarage}</td>
                          </tr>}
                        {building.BathStyle &&
                          <tr>
                            <th>Bath Style</th>
                            <td>{building.BathStyle}</td>
                          </tr>}
                        {building?.DwellingUnits != 0 && building.DwellingUnits &&
                          <tr>
                            <th>Building Units</th>
                            <td>{building?.DwellingUnits}</td>
                          </tr>
                        }
                        {building?.Condition &&
                          <tr>
                            <th>Condition</th>
                            <td>{building?.Condition}{/*Has Two Values*/}</td>
                          </tr>
                        }
                        {building?.CoolType &&
                          <tr>
                            <th>Cool Type</th>
                            <td>{building?.CoolType}</td>
                          </tr>
                        }
                        {building?.EconomicCode &&
                          <tr>
                            <th>Economic Code</th>
                            <td>{building?.EconomicCode}</td>
                          </tr>
                        }
                        {building?.EconomicPercent &&
                          <tr>
                            <th>Economic Percent Good</th>
                            <td>{building.EconomicPercent}%</td>
                          </tr>
                        }
                        {building?.ExterioWalls &&
                          <tr>
                            <th>Exterior Walls</th>
                            <td>{building.ExteriorWalls}</td>
                          </tr>
                        }
                        {building?.Grade &&
                          <tr>
                            <th>Construction Grade</th>
                            <td>{building?.Grade}</td>
                          </tr>
                        }
                        {building?.Factor != 0 && building?.Factor &&
                          <tr>
                            <th>Factor</th>
                            <td>{building.Factor}</td>
                          </tr>
                        }
                        {building?.FinishedBasementGrade &&
                          <tr>
                            <th>Finished Basement Grade</th>
                            <td>{building.FinishedBasementGrade}</td>
                          </tr>
                        }
                        {building?.FinishedBasementFactor != 0 && building?.FinishedBasementFactor &&
                          <tr>
                            <th>Finished Basement Factor</th>
                            <td>{building.FinishedBasementFactor}</td>
                          </tr>
                        }
                        {building?.Foundation &&
                          <tr>
                            <th>Foundation</th>
                            <td>{building.Foundation}</td>
                          </tr>
                        }
                        {building?.FunctionalPercent != 0 && building?.FunctionalPercent &&
                          <tr>
                            <th>Functional Percent Good</th>
                            <td>{building.FunctionalPercent}%</td>
                          </tr>
                        }
                        {building?.HeatType &&
                          <tr>
                            <th>Heat Type</th>
                            <td>{building.HeatType}</td>
                          </tr>
                        }
                        {building?.Insulation &&
                          <tr>
                            <th>Insulation</th>
                            <td>{building.Insulation}</td>
                          </tr>
                        }
                        {building?.KitchenStyle &&
                          <tr>
                            <th>Kitchen Style</th>
                            <td>{building.KitchenStyle}</td>
                          </tr>
                        }
                        {building?.Layout &&
                          <tr>
                            <th>Layout</th>
                            <td>{building.Layout}</td>
                          </tr>
                        }
                        {building?.NumberOfAdditionalFixtures != 0 && building?.NumberOfAdditionalFixtures &&
                          <tr>
                            <th>Number of Additional Fixtures</th>
                            <td>{building.NumberOfAdditionalFixtures}</td>
                          </tr>
                        }
                        {building?.Bedrooms != 0 && building?.Bedrooms &&
                          <tr>
                            <th>Number of Bedrooms</th>
                            <td>{building?.Bedrooms}</td>
                          </tr>
                        }
                        {building?.NumCars != 0 && building?.NumCars &&
                          <tr>
                            <th>Garage Capacity</th>
                            <td>{building?.NumCars}</td>
                          </tr>
                        }
                        {building?.Fireplaces != 0 && building?.Fireplaces &&
                          <tr>
                            <th>Number of Fireplaces</th>
                            <td>{building.Fireplaces}</td>
                          </tr>
                        }
                        {building?.FullBaths != 0 && building?.FullBaths &&
                          <tr>
                            <th>Number of Full Baths</th>
                            <td>{building.FullBaths}</td>
                          </tr>
                        }
                        {building?.HalfBaths != 0 && building?.HalfBaths &&
                          <tr>
                            <th>Number of Half Baths</th>
                            <td>{building.HalfBaths}</td>
                          </tr>
                        }
                        {building?.Rooms != 0 && building?.Rooms &&
                          <tr>
                            <th>Number of Rooms</th>
                            <td>{building.Rooms}</td>
                          </tr>
                        }
                        {building?.OtherUnits != 0 && building?.OtherUnits &&
                          <tr>
                            <th>Other Units</th>
                            <td>{building.OtherUnits}</td>
                          </tr>
                        }
                        {building?.PercentCooled != 0 && building?.PercentCooled &&
                          <tr>
                            <th>Percent Cooled</th>
                            <td>{building.PercentCooled}%</td>
                          </tr>
                        }
                        {building?.PercentHeated != 0 && building?.PercentHeated &&
                          <tr>
                            <th>Percent Heated</th>
                            <td>{building?.PercentHeated}%</td>
                          </tr>
                        }
                        {building?.PercentUnfinished != 0 && building?.PercentUnfinished &&
                          <tr>
                            <th>Percent Unfinished</th>
                            <td>{building.PercentUnfinished}%</td>
                          </tr>
                        }
                        {building?.PhysicalPercent != 0 && building?.PhysicalPercent &&
                          <tr>
                            <th>Physical Percent Good</th>
                            <td>{building.PhysicalPercent}%{/*Has Two Values*/}</td>
                          </tr>
                        }
                        {building?.RoofSurface &&
                          <tr>
                            <th>Roof Surface</th>
                            <td>{building.RoofSurface}</td>
                          </tr>
                        }
                        {building?.SquareFootBasementLiving != 0 && building?.SquareFootBasementLiving &&
                          <tr>
                            <th>Square Foot Basement Living</th>
                            <td>{building.SquareFootBasementLiving}</td>
                          </tr>
                        }
                        {building?.SquareFootMasonryTrim != 0 && building?.SquareFootMasonryTrim &&
                          <tr>
                            <th>Square Foot Masonry Trim</th>
                            <td>{building.SquareFootMasonryTrim}</td>
                          </tr>
                        }
                        {building?.BuildingStories &&
                          <tr>
                            <th>Stories</th>
                            <td>{building?.BuildingStories}</td>
                          </tr>
                        }
                        {building?.WetBasement &&
                          <tr>
                            <th>Wet Basement</th>
                            <td>{building?.WetBasement}</td>
                          </tr>
                        }
                        {building?.YearBuilt != 0 && building?.YearBuilt &&
                          <tr>
                            <th>Year Built</th>
                            <td>{building?.YearBuilt}</td>
                          </tr>
                        }
                        {building?.YearRemodeled != 0 && building?.YearRemodeled &&
                          <tr>
                            <th>Year Remodeled</th>
                            <td>{building?.YearRemodeled}</td>
                          </tr>
                        }
                        {building?.UserDefinedValues &&
                          building?.UserDefinedValues['Open3'] &&
                          <tr>
                            <th>{building?.UserDefinedValues['Open3']?.Description}</th>
                            <td>{building?.UserDefinedValues['Open3']?.UserDefinedValue}</td>
                          </tr>
                        }
                        {building?.UserDefinedValues &&
                          building?.UserDefinedValues['Open4'] &&
                          <tr>
                            <th>{building?.UserDefinedValues['Open4']?.Description}</th>
                            <td>{building?.UserDefinedValues['Open4']?.UserDefinedValue}</td>
                          </tr>
                        }
                        {building?.UserDefinedValues &&
                          building?.UserDefinedValues['Open4'] &&
                          <tr>
                            <th>{building?.UserDefinedValues['Open4']?.Description}</th>
                            <td>{building?.UserDefinedValues['Open4']?.UserDefinedValue}</td>
                          </tr>
                        }
                        {(building?.BuildingValue != 0 && building?.BuildingValue) &&
                          <tr>
                            <th>Value</th>
                            <td>${building?.BuildingValue.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                          </tr>}
                      </tbody>
                    </table>
                  ))}
                  {property?.CommercialBuildings?.map((cb, i) => (
                    <table className='table v-table' key={`commercial${i}`}>
                      <thead>Commercial</thead>
                      <tbody>
                        {cb?.OccupancyCode &&
                          <tr>
                            <th>Occupancy Type</th>
                            <td>{cb?.OccupancyCode}</td>
                          </tr>
                        }
                        {cb?.Class &&
                          <tr>
                            <th>Class</th>
                            <td>{cb?.Class}</td>
                          </tr>
                        }
                        {cb?.Quality != 0 && cb?.Quality &&
                          <tr>
                            <th>Grade of Construction</th>
                            <td>{cb?.Quality}</td>
                          </tr>}
                        {cb?.GradeFactor != 0 && cb?.GradeFactor &&
                          <tr>
                            <th>Grade Factor</th>
                            <td>{cb?.GradeFactor}%</td>
                          </tr>
                        }
                        {cb?.NumberDwellingUnits != 0 && cb?.NumberDwellingUnits &&
                          <tr>
                            <th>Dwelling Units</th>
                            <td>{cb?.NumberDwellingUnits}</td>
                          </tr>}
                        {cb?.ExteriorWalls && cb?.ExterioWalls != '' &&
                          <tr>
                            <th>Exterior Walls</th>
                            <td>{cb?.ExteriorWalls}</td>
                          </tr>}
                        {cb?.Stories &&
                          <tr>
                            <th>Stories</th>
                            <td>{cb?.Stories}</td>
                          </tr>}
                        {cb?.HeightPerStory != 0 && cb?.HeightPerStory &&
                          <tr>
                            <th>Height Per Story</th>
                            <td>{cb?.HeightPerStory + " ft"}</td>
                          </tr>}
                        {cb?.BaseFloorArea != 0 && cb?.BaseFloorArea &&
                          <tr>
                            <th>Base Floor Area</th>
                            <td>{cb?.BaseFloorArea}</td>
                          </tr>}
                        {cb?.PerimeterOrUnits != 0 && cb?.PerimeterOrUnits &&
                          <tr>
                            <th>Perimeter/Units</th>
                            <td>{cb?.PerimeterOrUnits}</td>
                          </tr>}
                        {cb?.HeatingCooling &&
                          <tr>
                            <th>Heating/Cooling</th>
                            <td>{cb?.HeatingCooling}</td>
                          </tr>}
                        {cb?.YearBuilt != 0 && cb?.YearBuilt &&
                          <tr>
                            <th>Year Built</th>
                            <td>{cb?.YearBuilt}</td>
                          </tr>}
                        {cb?.YearRemodeled != 0 && cb?.YearRemodeled &&
                          <tr>
                            <th>Year Remodeled</th>
                            <td>{cb?.YearRemodeled}</td>
                          </tr>}
                        {cb?.StructureCondition &&
                          <tr>
                            <th>Structure Condition</th>
                            <td>{cb?.StructureCondition}</td>
                          </tr>}
                        {cb?.PhysicalPercentGood != 0 && cb?.PhysicalPercentGood &&
                          <tr>
                            <th>Physical Percent Good</th>
                            <td>{cb?.PhysicalPercentGood + "%"}</td>
                          </tr>}
                        {cb?.FunctionalPercentGood != 0 && cb?.FunctionalPercentGood &&
                          <tr>
                            <th>Functional Percent Good</th>
                            <td>{cb?.FunctionalPercentGood + "%"}</td>
                          </tr>}
                        {cb?.EconomicPercentGood != 0 && cb?.EconomicPercentGood &&
                          <tr>
                            <th>Economic Percent Good</th>
                            <td>{cb?.EconomicPercentGood + "%"}</td>
                          </tr>}
                        {cb?.Value != 0 && cb?.Value &&
                          <tr>
                            <th>Value</th>
                            <td>${cb?.Value.toLocaleString()}</td>
                          </tr>}
                      </tbody>
                    </table>
                  ))}
                </fieldset>

              </div>
              <fieldset className={"property-data-section " + "building-addition" + " " + (collectionIsEmpty(property?.BuildingAdditions) ? "empty-section" : "")}>
                <legend>Additional Assessments</legend>
                <div className="no-data-overlay">No Data</div>
                {(isSmall || isMobile) &&
                  property?.BuildingAdditions?.map((addition, i) => (
                    <Fragment key={`additional${i}`}>
                      <table className='table v-table'>
                        <thead>{addition?.AdditionType}</thead>
                        <tbody>
                          {addition?.YearBuilt != 0 && addition?.YearBuilt &&
                            <tr>
                              <th>Year Built</th>
                              <td>{addition?.YearBuilt}</td>
                            </tr>
                          }
                          {addition?.Units &&
                            <tr>
                              <th>Units</th>
                              <td>{addition?.Units}</td>
                            </tr>
                          }
                          {addition?.Grade &&
                            <tr>
                              <th>Grade</th>
                              <td>{addition?.Grade}</td>
                            </tr>}
                          {addition?.Factor != 0 && addition?.Factor &&
                            <tr>
                              <th>Factor</th>
                              <td>{addition?.Factor}</td>
                            </tr>}
                          {addition?.Condition &&
                            <tr>
                              <th>Condition</th>
                              <td>{addition?.Condition}</td>
                            </tr>}
                          {addition?.PhysicalPercent != 0 && addition?.PhysicalPercent &&
                            <tr>
                              <th>Physical Percent Good</th>
                              <td>{addition?.PhysicalPercent}%</td>
                            </tr>}
                          {addition?.FunctionalPercentGood != 0 && addition?.FunctionalPercentGood &&
                            <tr>
                              <th>Functional Percent Good</th>
                              <td>{addition?.FunctionalPercentGood}%</td>
                            </tr>}
                          {addition?.AdditionValue != 0 && addition?.AdditionValue &&
                            <tr>
                              <th>Value</th>
                              <td>{"$" + addition?.AdditionValue}</td>
                            </tr>}
                        </tbody>
                      </table>
                    </Fragment>
                  ))
                }
                {!(isSmall || isMobile) &&
                  <table className='table h-table right-align'>
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Year Built</th>
                        <th>Units</th>
                        <th>Grade</th>
                        <th>Factor</th>
                        <th>Condition</th>
                        <th>Physical Percent Good</th>
                        <th>Functional Percent Good</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {property?.BuildingAdditions?.map(ba => (
                        <tr>
                          <td>{ba?.AdditionType}</td>
                          <td>{(ba?.YearBuilt != 0 && ba?.YearBuilt) && ba?.YearBuilt}</td>
                          <td>{(ba?.Units != 0 && ba?.Units) && ba?.Units}</td>
                          <td>{ba?.Grade}</td>
                          <td>{(ba?.Factor != 0 && ba?.Factor) && ba?.Factor}</td>
                          <td>{ba?.Condition}</td>
                          <td>{(ba?.PhysicalPercent != 0 && ba?.PhysicalPercent) && (ba?.PhysicalPercent + "%")}</td>
                          <td>{(ba?.FunctionalPercentGood != 0 && ba?.FunctionalPercentGood + "%")}</td>
                          <td>{(ba?.AdditionValue != 0 && ba?.AdditionValue) && ("$" + ba?.AdditionValue.toLocaleString())}</td>
                        </tr>
                      ))
                      }
                    </tbody>
                  </table>
                }

              </fieldset>
              <fieldset className={"tax-data" + " " + (collectionIsEmpty(property?.Taxes) ? "empty-section" : "")}>
              <legend>Tax Details {property?.Taxes && property?.Taxes?.length > 0 && property?.Taxes[0]?.EffectiveDate && "As Of " + formatDate(property?.Taxes[0].EffectiveDate)}</legend>
                <div className="no-data-overlay">No Data</div>
                {!(isSmall || isMobile) &&
                  <table className='table right-align h-table'>
                    <thead>
                      <tr>
                        <th>Year</th>
                        <th>Rate</th>
                        <th>Land Value</th>
                        <th>Building Value</th>
                        <th>Exemption Value</th>
                        <th>Taxable Value</th>
                        <th>Original Tax Amount</th>
                        <th>Outstanding Tax Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {property?.Taxes?.map(tx => (
                        <tr>
                          <td>{tx?.TaxYear}</td>
                          <td>{tx?.Rate > 0 && ((tx?.Rate * 1000).toLocaleString(undefined, { maximumFractionDigits: 4, minimumFractionDigits: 2 }))}</td>
                          <td>${tx?.LandValue?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                          <td>${tx?.BuildingValue?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                          <td>${tx?.ExemptionValue?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                          <td>${tx?.TaxableValue?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                          <td>${tx?.OriginalTaxAmount?.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                          <td>${tx?.OutstandingTaxBalance?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                        </tr>
                      ))
                      }
                    </tbody>
                  </table>
                }
                {(isSmall || isMobile) && property?.Taxes && property?.Taxes?.length > 0 &&
                  property?.Taxes?.map((tax, i) => (
                    <Fragment key={`tax${i}`} >
                      <table className='table v-table'>
                        <thead>{tax?.TaxYear}</thead>
                        <tbody>
                          {tax?.Rate != 0 && tax?.Rate &&
                            <tr>
                              <th>Rate</th>
                              <td>{(tax?.Rate * 1000).toLocaleString(undefined, { maximumFractionDigits: 4, minimumFractionDigits: 2 })}</td>
                            </tr>
                          }
                          <tr>
                            <th>Land Value</th>
                            <td>${tax?.LandValue?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                          </tr>
                          <tr>
                            <th>Building Value</th>
                            <td>${tax?.BuildingValue?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                          </tr>
                          <tr>
                            <th>Exemption Value</th>
                            <td>${tax?.ExemptionValue?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                          </tr>
                          <tr>
                            <th>Taxable Value</th>
                            <td>${tax?.TaxableValue?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</td>
                          </tr>
                          <tr>
                            <th>Original Tax Amount</th>
                            <td>${tax?.OriginalTaxAmount?.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                          </tr>
                          <tr>
                            <th>Outstanding Tax Balance</th>
                            <td>${tax?.OutstandingTaxBalance?.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</td>
                          </tr>
                        </tbody>
                      </table>
                    </Fragment>
                  ))
                }
              </fieldset>
              {
                <div className ="footer">
                  {showButton ? <button className ="btn" onClick={openURL}>Pay Now</button> :""}
                </div>
              }
            </div>
          </div>
        }
        {isViewerOpen ?
          <ImageViewer
            src={property?.Images.map((image, index) => (`${process.env.REACT_APP_BACKEND_ORIGIN}${image}`))}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
            backgroundStyle={{ backgroundColor: "#403d5299", zIndex: 998 }}
          />
          : <></>}
      </div>
      <div className='result-nav-bar shadow'>
        <Link className='btn return-link' to={`/${params.state}/${params.city}/search`}><img src={backArrowIcon} />Return to search</Link>
        {showResultNav ? <div>
          <button className='btn' onClick={goToPrevResult}><span>&#8249;</span> Previous</button>
          <p>{currentPosition}/{results ? results.length : 1}</p>
          <button className='btn' onClick={goToNextResult}>Next <span>&#8250;</span></button>
        </div> : <></>}
        <p>To Print To PDF, press CTRL and P</p>
      </div>
    </Fragment>
  );
}

export default Listing;