import './ContactModal.css';
import '../Modal.css';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Transition } from "react-transition-group";



//If contact is empty, we are creating a new contact
function ContactModal({isOpen, setOpen, contact, clientID, setContacts}) {
    const [contactInfo, setContactInfo] = useState(contact ? contact : {
        ClientID: clientID,
        ContactEmail: "",
        ContactUrl: "",
        FreeFormMessage: "",
        HoursOfOperation: "",
        Title: "",
        Telephone: ""
    });
    const [error, setError] = useState(null);

    const closeModal = () => {setOpen(false)};

    /**
     * Update contact info object
     * @param {String} prop The property to change
     * @param {*} value The value to set 
     */
    const updateInfo = (prop, value) => {
        let tempContact = JSON.parse(JSON.stringify(contactInfo));
        // console.log(contact, contactInfo, tempContact);
        tempContact[prop] = value;
        setContactInfo(tempContact);
    } 

    /**
     * Attempt to save new client information
     */
    const saveNewInfo = () => {
        //update existing contact
        let fetchBody = {
            ClientID: clientID,
            ContactEmail: contactInfo.ContactEmail,
            ContactUrl: contactInfo.ContactUrl,
            FreeFormMessage: contactInfo.FreeFormMessage,
            HoursOfOperation: contactInfo.HoursOfOperation,
            Title: contactInfo.Title,
            Telephone: contactInfo.Telephone
        };
        if(contactInfo.hasOwnProperty("ContactID")) {
            let accessToken = localStorage.getItem('accessToken');
            fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/contact/${contactInfo.ContactID}`, {
                method: "PUT",
                mode: 'cors',
                credentials: "include",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization' : accessToken
                },
                body: JSON.stringify(fetchBody)
              }).then(res => res.json()).then(value => {
                if(value.error) {
                    //show error
                    console.log(value);
                    toast.error("An error occured");
                    // setError(value.error);
                }
                else {
                    //successfully updated contact
                    toast.success("Contact saved");
                    setContacts((prev) => {
                        return updateContactsState(prev, value);
                    })
                    closeModal();
                }
            });
        }
        //New contact
        else {
            let accessToken = localStorage.getItem('accessToken');
            fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/contact`, {
                method: "POST",
                mode: 'cors',
                credentials: "include",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization' : accessToken
                },
                body: JSON.stringify(fetchBody)
              }).then(res => res.json()).then(value => {
                if(value.error) {
                    //show error
                    setError(value.error);
                }
                else {
                     //successfully created contact
                    setContacts((prev) => {
                        let temp = prev;
                        temp.push(value);
                        return temp;
                    });
                    closeModal();
                }
               
            });
        }
    }

    /**
     * Update contact in list of contacts
     * @param {*} prev List of contacts
     * @param {*} data New contact object
     * @returns Updated list of contacts
     */
    const updateContactsState = (prev, data) => {
        let temp = prev;
        for(let i = 0; i < temp.length; i++) {
            if(temp[i].ContactID == data.ContactID) {
                temp[i] = data;
                break;
            }
        }
        return temp;
    }

    /**
     * When contact is updated, use contact data if it exists, otherwise use default values
     * Necessary since component doesnt unmount. It stays alive and just changes info/state
     */
    useEffect(() => {
        setContactInfo(contact ? contact : {
            ClientID: clientID,
            ContactEmail: "",
            ContactUrl: "",
            FreeFormMessage: "",
            HoursOfOperation: "",
            Title: "",
            Telephone: ""
        });
        setError(null);
    }, [contact]);

    // if(isOpen) 
    return (
        <Transition in={isOpen} timeout={{ enter: 0, exit: 150 }} appear>
        {state => (
        <div className={`contact-modal modal-container ${state}`}>
            <div className="screen" onClick={closeModal}></div>
            <div className="modal">
                <button className="close-modal-btn" onClick={closeModal}>✕</button>
                <div className="modal-header">
                    <h1>Contact Info</h1>
                    {/* <h2>{location}</h2> */}
                </div>
                <div className='modal-body'>
                    <div className='split-section'>
                        <div className='left-side'>
                            <div className='input-group'>
                                <label htmlFor="contact-title-input">Name/Title</label>
                                <input id="contact-title-input" value={contactInfo?.Title} onChange={(event) => updateInfo("Title", event.target.value)} />
                            </div>
                            <div className='input-group'>
                                <label htmlFor="contact-phone-input">Phone Number</label>
                                <input id="contact-phone-input" value={contactInfo?.Telephone} onChange={(event) => updateInfo("Telephone", event.target.value)} />
                            </div>
                            <div className='input-group'>
                                <label htmlFor="contact-url-input">Home URL</label>
                                <input id="contact-url-input" value={contactInfo?.ContactUrl} onChange={(event) => updateInfo("ContactUrl", event.target.value)} />
                            </div>
                            <div className='input-group'>
                                <label htmlFor="contact-email-input">Email Address</label>
                                <input id="contact-email-input" value={contactInfo?.ContactEmail} onChange={(event) => updateInfo("ContactEmail", event.target.value)} />
                            </div>
                        </div>
                        <div className='right-side'>
                            <div className='input-group'>
                                <label htmlFor="contact-hours-input">Hours of Operation</label>
                                <input id="contact-hours-input" value={contactInfo?.HoursOfOperation} onChange={(event) => updateInfo("HoursOfOperation", event.target.value)} />
                            </div>
                            <div className='input-group'>
                                <label htmlFor="contact-freeform-input">Free Form Message</label>
                                <textarea id="contact-freeform-input" value={contactInfo?.FreeFormMessage} onChange={(event) => updateInfo("FreeFormMessage", event.target.value)} />
                            </div>
                        </div>
                    </div>
                    {error ? <p className='error-msg center'>{error}</p> : <></>}
                    <button className='btn center primary' onClick={saveNewInfo}>Update</button>
                </div>
            </div>
        </div>
        )}
        
        </Transition>

    );
    // else return <></>;
}

export default ContactModal;