import { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import './Login.css';
import { UserContext } from "../../hooks/useContext";

function Login({ harrisAdmin }) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);
    const [clientID, setClientID] = useState();
    const [user, setUser] = useContext(UserContext);

    let params = useParams();

    /**
     * On page load, fetch and store client id
     */
    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/client/${params.state}/${params.city}`)
            .then(res => res.json())
            .then(data => {
                setClientID(data.ClientID);

            });
    }, []);

    /**
     * Call attemptLogin when the Enter key is pressed
     * @param {*} event Keyboard event
     */
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') attemptLogin();
    }

    /**
     * Attempt to log the user in
     */
    const attemptLogin = () => {
        fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/login`, {
            method: "POST",
            mode: 'cors',
            credentials: "include",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ Identity: username, Password: password })
        }).then(res => res.json()).then(value => {
            console.log(value)
            if (value.Status == 401) {
                setError(true);
            }
            else {

                //render next component
                if (value.user.RoleID == 1 || value.user.Manages == clientID) {

                    setUser(value.user);
                    localStorage.setItem('UserId', value.user.UserId);
                    localStorage.setItem('accessToken', value.accessToken);
                }
                else {
                    //console.log("User exists but does not have access to manage this client");
                    setError(true);
                }

            }
        });
    }

    return (
        <div className="login-page">
            <div className="header-section">
                <Link to="/"><h1>Harris Online Property Portal</h1></Link>
            </div>
            <div className="form-section shadow">
                <h2>{harrisAdmin ? "Harris Admin Login" : `${params.city}, ${params.state}`}</h2>
                <div className='input-group'>
                    <label htmlFor="username-input">Username</label>
                    <input id="username-input" autoComplete="off" value={username} onChange={(event) => setUsername(event.target.value)} onKeyDown={handleKeyDown} />
                </div>
                <div className='input-group'>
                    <label htmlFor="password-input">Password</label>
                    <input type="password" id="password-input" autoComplete="off" value={password} onChange={(event) => setPassword(event.target.value)} onKeyDown={handleKeyDown} />
                </div>
                {error ? <p className='error-msg center'>Username or password was incorrect</p> : <></>}
                <button className='btn center primary' onClick={attemptLogin} >Log In</button>
                <Link className='btn center secondary link' to={`/${params.state}/${params.city}/search`}>Return to search</Link>
            </div>
        </div>
    );
}


export default Login;