import './HarrisAdminEditModal.css';
import '../Modal.css'
import { toast } from 'react-hot-toast';
import { Transition } from "react-transition-group";

import { useEffect, useState } from 'react';

function HarrisAdminEditModal({isOpen, setOpen, selectedHarrisAdmin, updateHarrisAdmin}) {
    const [name, setName] = useState(selectedHarrisAdmin ? selectedHarrisAdmin.Username : "");
    const [email, setEmail] = useState(selectedHarrisAdmin ? selectedHarrisAdmin.Email : "");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState(null);

    const closeModal = () => {setOpen()}

    const passwordsMatch = () => {
        return password === confirmPassword;
    }

    /**
     * Attempt to save admin information
     * @returns if the method should not continue
     */
    const saveNewInfo = () => {
        if(!passwordsMatch()) {
            // setError("Passwords do not match");
            toast.error('Passwords do not match')
            return;
        }

        let fetchBody = {
            Username: name,
            Email: email,
            RoleID: 1,
            Manages: null
        };
        //If a password is typed in and it matches the confirm password, send to backend
        if(password.length > 0) fetchBody.Password = password;

        //update existing admin
        let accessToken = localStorage.getItem('accessToken');
        if(selectedHarrisAdmin.hasOwnProperty("UserId")) {
            fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/user/${selectedHarrisAdmin.UserId}`, {
                method: "PUT",
                mode: 'cors',
                credentials: "include",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization' : accessToken
                },
                body: JSON.stringify(fetchBody)
              }).then(res => res.json()).then(value => {
                if(value.error) {
                    //show error
                    // setError(value.error);
                    toast.error("An error occured");
                }
                else {
                    //successfully updated user
                    toast.success("Admin saved");
                    updateHarrisAdmin((prev) => {
                        return updateAdminsState(prev, value);
                    })
                    closeModal();
                }
            });
        }
        //New admin
        else {
            let accessToken = localStorage.getItem('accessToken');
            fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/user`, {
                method: "POST",
                mode: 'cors',
                credentials: "include",
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  'Authorization' : accessToken
                },
                body: JSON.stringify(fetchBody)
              }).then(res => res.json()).then(value => {
                if(value.error) {
                    //show error
                    setError(value.error);
                }
                else {
                     //successfully created user
                    updateHarrisAdmin((prev) => {
                        let temp = prev;
                        temp.push(value);
                        return temp;
                    });
                    closeModal();
                    // console.log(value);
                }
               
            });
        }
    }

    /**
     * Update admin in list of admins
     * @param {*} prev List of admins
     * @param {*} data New admin object
     * @returns Updated list of admins
     */
    const updateAdminsState = (prev, data) => {
        let temp = prev;
        for(let i = 0; i < temp.length; i++) {
            if(temp[i].UserId == data.UserId) {
                temp[i] = data;
                break;
            }
        }
        return temp;
    }

    /**
     * When selectedHarrisAdmin is updated, use selectedHarrisAdmin data if it exists, otherwise use default values
     * Necessary since component doesnt unmount. It stays alive and just changes info/state
     */
    useEffect(() => {
        setName(selectedHarrisAdmin ? selectedHarrisAdmin.Username : "");
        setEmail(selectedHarrisAdmin ? selectedHarrisAdmin.Email : "");
    },[selectedHarrisAdmin])

    // if(isOpen)
    return (
        <Transition in={isOpen} timeout={{ enter: 0, exit: 150 }} appear>
        {state => (
        <div className={`harris-admin-modal modal-container ${state}`}>
            <div className='screen' onClick={closeModal}></div>
            <div className='modal'>
                <button className='close-modal-btn' onClick={closeModal}>✕</button>
                <div className='modal-header'>
                    <h1>Harris Admin</h1>
                </div>
                <div className='modal-body'>
                    <div className='split-section'>
                        <div className='left-side'>
                            <div className='input-group'>
                                <label htmlFor='name-input'>Name</label>
                                <input type="name" id="name-input" autoComplete='off' value={name} onChange={(event) => setName(event.target.value)} />
                            </div>
                            <div className='input-group'>
                                <label htmlFor='email-input'>Email</label>
                                <input type="email" id='email-input' autoComplete='off' value={email} onChange={(event) => setEmail(event.target.value)} />
                            </div>
                        </div>
                        <div className='right-side'>
                            <div className='input-group'>
                                <label htmlFor='password-input'>New Password</label>
                                <input type="password" id="password-input" autoComplete='off' value={password} onChange={(event) => setPassword(event.target.value)} />
                            </div>
                            <div className='input-group'>
                                <label htmlFor='new-password-input'>Confirm Password</label>
                                <input type="password" id="new-password-input" autoComplete='off' value={confirmPassword} onChange={(event) => setConfirmPassword(event.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className='addUpdate-btn'>
                        <button className='btn primary' onClick={saveNewInfo}>{selectedHarrisAdmin.hasOwnProperty("UserId") ? "Update" : "Add"}</button>
                    </div>
                </div>
            </div>
        </div>
        )}
        
        </Transition>
    )
}

export default HarrisAdminEditModal;