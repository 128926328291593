import { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import magnifyingGlass from "../../icons/magnifying-glass-solid.svg";
import ReactTooltip from 'react-tooltip';
import toast, { Toaster } from 'react-hot-toast';

import HarrisAdminEditModal from "../../partials/HarrisAdminEditModal/HarrisAdminEditModal";
import ClientCreationModal from "../../partials/ClientCreationModal/ClientCreationModal";
import ClientConfigModal from '../../partials/ClientConfigModal/ClientConfigModal';

import './HarrisAdminClientPage.css';

import { UserContext } from "../../hooks/useContext";

function HarrisAdminClientPage() {
    const [user, setUser] = useContext(UserContext);

    const [isHarrisAdminEditModalOpen, setIsHarrisAdminEditModalOpen] = useState(false);
    const [isClientCreationModalOpen, setIsClientCreationModalOpen] = useState(false);
    const [isClientConfigModalOpen, setIsClientConfigModalOpen] = useState(false);
    const [selectedClient, setSelectedClient] = useState(false);
    const [selectedHarrisAdmin, setSelectedHarrisAdmin] = useState(false);
    const [filteredClients, setFilteredClients] = useState([]);

    const [roleId, setRoleId] = useState();
    const [loading, setLoading] = useState(true);

    const openHarrisAdminEditModal = () => { setIsHarrisAdminEditModalOpen(true); }
    const openClientCreationModal = () => { setIsClientCreationModalOpen(true); }
    const openClientConfigModal = () => { setIsClientConfigModalOpen(true); }
    const updateClientList = (newList) => { setClientList(newList); }
    const updateHarrisAdmin = (newList) => { setAdminList(newList); }

    const [clientList, setClientList] = useState();
    const [adminList, setAdminList] = useState();
    const [townInformation, setTownInformation] = useState({});
    const [error, setError] = useState(false);
    const [clientId, setClientId] = useState();
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [filters, setFilters] = useState({
        sale: {
            price: {
                start: "",
                end: ""
            },
            date: {
                start: "",
                end: ""
            }
        },
        land: {
            area: {
                start: "",
                end: ""
            },
            type: []
        },
        building: {
            area: {
                start: "",
                end: ""
            },
            style: [],
            bedrooms: "",
            bathrooms: "",
            ownership: []
        }
    });

    const [sorts, setSorts] = useState({
        City: { direction: "off", priority: -1 }
    })

    const [townInfo, setTownInfo] = useState();

    const updateIsHarrisAdminModalOpen = () => {
        setIsHarrisAdminEditModalOpen(false)
        setSelectedHarrisAdmin("")
    }

    const getDataFieldByKey = (data, key) => {
        switch (key) {
            case "City": return data?.City;
        }
    }

    useEffect(() => {
        try {
            let tempResults = JSON.parse(JSON.stringify(searchResults));
            let sortedList = JSON.parse(JSON.stringify(sorts));

            sortedList = (Object.entries(sortedList).filter(s => s[1].priority != -1));

            sortedList.sort((a, b) => b[1].priority - a[1].priority);

            sortedList.forEach(sort => {
                if (sort[1].direction === "descending") {
                    if (sort[0] === "City") {
                        tempResults.sort((a, b) => getDataFieldByKey(b, sort[0]) - getDataFieldByKey(a, sort[0]));
                    }
                    else {
                        tempResults.sort((a, b) => getDataFieldByKey(b, sort[0]).localeCompare(getDataFieldByKey(a, sort[0])));
                    }
                }
                if (sort[1].direction === "ascending") {
                    if (sort[0] === "City") {
                        tempResults.sort((a, b) => getDataFieldByKey(a, sort[0]) - getDataFieldByKey(b, sort[0]));
                    }
                    else {
                        tempResults.sort((a, b) => getDataFieldByKey(a, sort[0]).localeCompare(getDataFieldByKey(b, sort[0])));
                    }
                }
            })

            setSearchResults(tempResults);
        } catch (error) {
            console.error(error)
        }
    }, [sorts]);

    const getSortDisplay = (sort) => {
        switch (sorts[sort].direction) {
            case "off":
                return "&#9650; &#9660;";
            case "desending":
                return "&nbsp; &#9660;";
            case "ascending":
                return "&#9650;";

        }
    }

    const addToggleSort = (sort) => {
        let tempSorts = JSON.parse(JSON.stringify(sorts));

        if (tempSorts[sort].priority == -1) {
            let sortedList = Object.values(sorts).sort((a, b) => b.priority - a.priority);

            tempSorts[sort].priority = sortedList[0].priority + 1;

            tempSorts[sort].direction = toggleDirOfSort(tempSorts[sort].direction);
        }
        else {
            tempSorts[sort].direction = toggleDirOfSort(tempSorts[sort].direction);

            if (tempSorts[sort].direction === "off") {
                let priority = tempSorts[sort].priority;
                let sortedList = tempSorts;
                Object.values(sortedList).sort((a, b) => a.priority - b.priority);
                tempSorts = sortedList;
                Object.values(tempSorts)?.map((s) => {
                    if (s.priority > priority) s.priority = s.priority - 1;
                    return s;
                });
                tempSorts[sort].priority = -1;
            }
        }
        setSorts(tempSorts);
    }

    const toggleSort = (sort) => {
        let tempSorts = JSON.parse(JSON.stringify(sorts));
        tempSorts[sort].direction = toggleDirOfSort(tempSorts[sort].direction);

        Object.entries(tempSorts)?.map(s => {
            if (s[0] != sort) {
                s[1].priority = -1;
                s[1].direction = "off";
            }
            return s;
        });
        tempSorts[sort].priority = 0;

        setSorts(tempSorts);
    }

    const toggleDirOfSort = (dir) => {
        switch (dir) {
            case "off":
                return "descending";
            case "descending":
                return "ascending";
            case "ascending":
                return "off";
        }
    }

    /**
     * Log the user out
     */
    const logout = () => {
        fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/logout`, {
            method: "POST",
            credentials: "include",
        }).then(() => {
            setUser(null);
            localStorage.setItem('UserId', null);
            localStorage.setItem('accessToken', null);

        });
    }

    const search = (hideSkeleton) => {
        fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/search/${clientId}?query=${searchQuery}`, {
            method: "POST",
            mode: 'cors',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ filters: filters })
        }).then(res => res.json()).then(value => {
            console.log(value);
            setSearchResults(value);
            if (hideSkeleton) setLoading(false);
        });
    }

    /**
     * On page load, fetch a list of clients
     */
    let accessToken = localStorage.getItem('accessToken');
    useEffect(() => {
        fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/clients/`, {
            credentials: "include",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': accessToken
            }
        }).then(res => res.json(), [accessToken])
          .then(data => {
                let sortedData = data.sort((a, b) => {
                    return a.City.localeCompare(b.City);
                });
                setClientList(sortedData);
            }).catch(error => {
                
                //setUser(null);
                /*localStorage.setItem('UserId', null);
                localStorage.setItem('accessToken', null);*/
                console.error('Error fetching admins:', error)

               


            }
            ).then();
    }, [accessToken]);



    /**
     * On page load, fetch a list of admins
     */

    useEffect(() => {
        let accessToken = localStorage.getItem('accessToken');
        fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/users/role/1`, {
            credentials: "include",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': accessToken
            }
        }).then(response => {
            //.then(res => res.json()).then(data => {
            if (!response.ok) {
                setUser(null);
                localStorage.setItem('UserId', null);
                localStorage.setItem('accessToken', null);

                console.error('There was a problem loading the users:', error);
            }
            else {
                return response.json().then(data => {

                    let sortedAdmins = data.sort((a, b) => {
                        return a.Username.localeCompare(b.Username);
                    });
                    setAdminList(sortedAdmins);
                })
            }
        })


    }, []);

    useEffect(() => {
        if (clientId) {
            fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/client/${clientId}?showinactive=true`).then(res => res.json()).then(value => {
                setTownInformation(value);
            });
            fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/api/contacts/${clientId}`).then(res => res.json()).then(value => setTownInfo(value));
            search(true);
        }
    }, [clientId]);

    // useEffect(() => {
    //     console.log(clientList)
    // },[clientList])

    // useEffect(() => {
    //     console.log(adminList)
    // },[adminList])

    return (
        <>
            <div className='harrisadminclient-page'>
                <div><Toaster /></div>
                <div className='header-section'>
                    <Link to="/"><h1>Harris Online Property Portal</h1></Link>
                    <div className='user'>
                        <p>{user?.Username}</p>
                        <div className='dropdown'>
                            <button className='dropdown-item' onClick={logout}>Log out</button>
                        </div>
                    </div>
                </div>
                <div className='item-list'>
                    <div className='clients'>
                        <div className='client-header'>
                            {/* <div className="input-bar">
                        <input type="text" />
                            <button className="search-btn" onClick={() => search()} data-background-color="#8781a1" data-tip="Search" data-for="searchbtn"><img src={magnifyingGlass} className="magnifying-glass-icon"/></button>
                            <ReactTooltip id="searchbtn" place="top" type="info" effect="solid" />
                        </div> */}
                        </div>
                        <div className='client-results'>
                            <div className='data-header2'>
                                <div className='data-header-desktop2'>
                                    <div className='client'>
                                        <span>Client</span>
                                    </div>
                                    <div className='clientID'>
                                        <span>ID</span>
                                    </div>
                                    <div className='parcel'>
                                        <span>Parcels</span>
                                    </div>
                                    <div className='date'>
                                        <span>Date Last Updated</span>
                                    </div>
                                    <div className='addbutton'>
                                        <button className='btn primary' onClick={openClientCreationModal}>Add</button>
                                    </div>
                                </div>
                            </div>
                            <div className='client-list'>
                                {Array.isArray(clientList) ? clientList.map((value, index) => {
                                    return (
                                        <>
                                            <div className='name'>
                                                <h4>{value.City}</h4>
                                            </div>
                                            <div className='clID'>
                                                <span className='data'>{value.ClientID}</span>
                                            </div>
                                            <div className='numParcel'>
                                                <span className='data'>{value.ParcelCount}</span>
                                            </div>
                                            <div className='dateLastUpdated'>
                                                <span className='data'>{value?.LastUpdated && (new Date(value?.LastUpdated)).toLocaleDateString("en-US")}</span>
                                            </div>
                                            <div className='edit'>
                                                <button className='btn secondary' onClick={() => { setSelectedClient(value); openClientConfigModal(); }}>Edit</button>
                                            </div>
                                        </>
                                    );
                                }) : <></>}

                            </div>
                        </div>
                    </div>
                    <fieldset className='harris-admins'>
                        <legend>Harris Administrator</legend>
                        <div className='legend2'><button className='btn primary' onClick={openHarrisAdminEditModal}>Add</button></div>
                        {Array.isArray(adminList) ? adminList.map((value, index) => {
                            return (
                                <>
                                    <div className='harrisAccounts'>
                                        <div className='harrisName'>
                                            <h4>{value.Username}</h4>
                                        </div>
                                        <div className='edit'>
                                            <button className='btn secondary' onClick={() => { setSelectedHarrisAdmin(value); openHarrisAdminEditModal(); }}>Edit</button>
                                        </div>
                                    </div>
                                </>
                            );
                        }) : <> </>
                        }
                    </fieldset>
                </div>
                {error ? <p className='error-msg center'>An error has occured please try again</p> : <></>}
            </div>
            <HarrisAdminEditModal isOpen={isHarrisAdminEditModalOpen} setOpen={updateIsHarrisAdminModalOpen} selectedHarrisAdmin={selectedHarrisAdmin} updateHarrisAdmin={updateHarrisAdmin} />
            <ClientCreationModal isOpen={isClientCreationModalOpen} setOpen={setIsClientCreationModalOpen} />
            <ClientConfigModal isOpen={isClientConfigModalOpen} setOpen={setIsClientConfigModalOpen} selectedClient={selectedClient} updateClientList={updateClientList} />
        </>
    );
}

export default HarrisAdminClientPage;